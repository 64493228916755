const Input = ({ id, value, onChange, required, type, setErr, disabled }) => {
  return (
    <>
      <input
        id={id}
        type={type}
        className="text-black text-xl w-full text-center bg-white shadow-md shadow-gray-300 rounded-full px-4 py-2"
        value={value}
        onChange={(e) => {
          setErr("");
          onChange(e.target.value);
        }}
        required={required}
        disabled={disabled}
      />
    </>
  );
};

export default Input;
