import { useEffect, useState } from "react";
import { miniMarketLogin } from "../requests/miniMarkets/sendData";
import { useNavigate } from "react-router-dom";
import { PasswordInput } from "../components/passwordInput";
import Input from "../components/input";
import { ErrAlert } from "../components/alerts";

const MiniMarketLogin = ({ setIsMiniMarket }) => {
  const [clientId, setClientId] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setIsMiniMarket(false);
  }, [setIsMiniMarket]);

  const handleSubmit = (e) => {
    e.preventDefault();
    miniMarketLogin(clientId, password, setErr, navigate);
  };

  return (
    <>
      <h1 className="font-black text-3xl text-center px-12 py-12">
        εισοδος καταστηματος
      </h1>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col space-y-4 w-full items-center justify-end px-12"
      >
        <label
          className="text-black text-sm w-full text-center"
          htmlFor="clientId"
        >
          κωδικος πελατη:
        </label>
        <Input
          id="clientId"
          type="number"
          value={clientId}
          onChange={setClientId}
          required={true}
          setErr={setErr}
        />
        <label
          className="text-black text-sm w-full text-center"
          htmlFor="password"
        >
          password συνδεσης:
        </label>
        <PasswordInput
          id="password"
          password={password}
          setPassword={setPassword}
          setErr={setErr}
        />
        <button
          type="submit"
          className="rounded-full bg-coca-cola-red border border-white px-12 py-3 text-white font-bold text-2xl uppercase disabled:bg-coca-cola-disabled"
        >
          εισοδος
        </button>
        {err && <ErrAlert err={err} setErr={setErr} />}
      </form>
    </>
  );
};

export default MiniMarketLogin;
