import { useEffect, useState } from "react";
import { getWinners } from "../requests/admin/getData";
import { formatDate } from "../utils";
import Table from "../components/table";
import { createColumnHelper } from "@tanstack/react-table";

const AdminWinnersList = ({ navigate }) => {
  const [winners, setWinners] = useState();
  const columnHelper = createColumnHelper();
  const [selectedWinner, setSelectedWinner] = useState();

  const columns = [
    columnHelper.accessor("full_name", {
      header: "ονομα",
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("phone", {
      header: "τηλεφωνο",
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("receipt_created_on", {
      header: "ωρα",
      cell: (info) => formatDate(info.getValue()),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("file", {
      header: "φωτο",
      cell: (info) => (
        <div className="h-56">
          <img
            src={info.getValue()}
            alt={"αποδειξη"}
            className="h-full bg-contain"
          />
        </div>
      ),
      footer: (info) => info.column.id,
    }),
  ];

  useEffect(() => {
    getWinners(setWinners, navigate);
  }, [navigate]);

  return (
    <>
      {selectedWinner && (
        <div className="absolute flex flex-col justify-center items-center z-10 overflow-y-auto p-16 bg-white space-y-4 h-4/5 w-4/5 shadow-lg">
          <button
            type="button"
            className="ms-auto text-white rounded-full inline-flex items-center justify-center h-8 w-8 py-4 bg-coca-cola-red"
            onClick={() => setSelectedWinner()}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
          <div className="flex space-x-4">
            <input
              id="fullName"
              disabled
              className="text-black w-full text-center bg-white shadow-md shadow-gray-300 rounded-full px-4 py-2"
              value={selectedWinner.full_name}
            />
            <input
              id="phone"
              disabled
              className="text-black w-full text-center bg-white shadow-md shadow-gray-300 rounded-full px-4 py-2"
              value={selectedWinner.phone}
            />
            <input
              id="dateTime"
              disabled
              className="text-black w-full text-center bg-white shadow-md shadow-gray-300 rounded-full px-4 py-2"
              value={formatDate(selectedWinner.receipt_created_on)}
            />
          </div>
          <img
            src={selectedWinner.file}
            alt={"αποδειξη"}
            className="h-full bg-contain"
          />
        </div>
      )}
      <div className="w-full overflow-y-auto flex flex-col space-y-4 items-center">
        <Table
          data={winners}
          columns={columns}
          isClickable={true}
          setSelectedWinner={setSelectedWinner}
          emptyMsg="Δεν υπαρχουν νικητες"
        />
      </div>
    </>
  );
};

export default AdminWinnersList;
