const Contact = () => {
  return (
    <div className="flex flex-col space-y-4 py-4 px-10">
      <p className="text-center text-3xl underline font-black pt-12">
        επικοινωνια
      </p>
      <div className="font-bold text-lg">
        <p>τηλεφωνο επικοινωνιας:</p>
        <p className="text-coca-cola-red">2109525255</p>
      </div>
      <div className="font-bold text-lg">
        <p>chapter5</p>
        <p>τμημα δωρων coca-cola</p>
      </div>
      <div className="font-bold text-lg">
        <p>ωρες λειτουργιας:</p>
        <p className="text-coca-cola-red">δευτερα-παρασκευη</p>
        <p className="text-coca-cola-red">11:00-14:00</p>
        <p className="text-coca-cola-red">(εκτος αργιων)</p>
      </div>
    </div>
  );
};

export default Contact;
