import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../requests/admin/sendData";
import { ErrAlert } from "../components/alerts";
import Input from "../components/input";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  var userAgent = window.navigator.userAgent;

  const handleSubmit = (e) => {
    e.preventDefault();
    adminLogin(email, password, navigate, setErr);
  };

  return (
    <div
      className={
        "w-screen relative flex flex-col overflow-y-auto font-tccc uppercase bg-coca-cola-light-gray items-center justify-center space-y-8 " +
        (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)
          ? "h-dvh"
          : "h-screen")
      }
    >
      <h1 className="text-2xl font-black">συνδεση</h1>
      <form
        className="flex flex-col space-y-4 w-auto items-center justify-end px-12"
        onSubmit={handleSubmit}
      >
        <label htmlFor="email" className="text-sm w-full text-center">
          email:
        </label>
        <Input
          id="email"
          value={email}
          onChange={setEmail}
          type="text"
          setErr={setErr}
          disabled={false}
          required={true}
        />
        <label htmlFor="password" className="text-sm w-full text-center">
          κωδικος προσβασης
        </label>
        <Input
          id="password"
          type="password"
          value={password}
          onChange={setPassword}
          setErr={setErr}
          disabled={false}
          required={true}
        />
        <button
          type="submit"
          className="rounded-full bg-coca-cola-red border border-white px-8 py-2 text-white font-bold text-lg uppercase"
        >
          συνδεση
        </button>
        {err && <ErrAlert err={err} setErr={setErr} />}
      </form>
    </div>
  );
};

export default AdminLogin;
