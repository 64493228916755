import { useEffect } from "react";
import { Link } from "react-router-dom";
import { DummyQrIcon, GiftCardsIcon } from "../assets/icons";
import cansImg from "../assets/Cans_100x114px.png";

const Landing = ({ setIsMiniMarket }) => {
  useEffect(() => {
    setIsMiniMarket(false);
  }, [setIsMiniMarket]);

  return (
    <div className="flex flex-col items-center bg-coca-cola-red space-y-4">
      <div className="font-bold text-white pt-2">
        <h1 className="text-2xl text-center">καθε μερα ενας νικητης!</h1>
      </div>
      <div className="rounded-md bg-white text-coca-cola-red text-md text-center">
        <p className="font-black px-3">αποκλειστικα στα mini markets</p>
      </div>
      <div className="grid grid-cols-3 grid-rows-3 justify-center items-center justify-items-center text-md text-center font-bold text-white px-4">
        <img src={cansImg} className="w-20 bg-contain" alt="Cans" />
        <div className="border-b-4 border-dotted h-full w-full flex items-center px-4 col-span-2">
          <p className="hidden md:inline-block w-full">
            προσθεσε coca-cola στο καλαθι σου
          </p>
          <p className="md:hidden w-full">
            προσθεσε
            <br />
            coca-cola
            <br />
            στο καλαθι σου
          </p>
        </div>
        <DummyQrIcon />
        <div className="border-b-4 border-dotted h-full w-full flex items-center px-4 col-span-2">
          <p className="w-full">
            σκαναρε τον κωδικο qr που θα βρεις στα καταστηματα
          </p>
        </div>
        <GiftCardsIcon />
        <div className="h-full w-full flex items-center px-4 col-span-2">
          <p className="w-full">
            καταχωρησε την αποδειξη σου και δες αν κερδισες μια δωροεπιταγη!
          </p>
        </div>
      </div>

      <p className="text-center normal-case text-2xs text-white">
        Στην ενέργεια συμμετέχουν όλες οι συσκευασίες Coca-Cola. Πιθανώς να μην
        διατίθενται όλες οι συσκευασίες Coca-Cola λόγω έλλειψης χώρου. Διάρκεια
        διαγωνισμού από 08/04/2024 έως 31/12/2024. Ισχύουν όροι και
        προϋποθέσεις.
      </p>
      <div className="w-full bg-coca-cola-light-gray">
        <p className="text-md text-center py-8 px-4">
          για καταστηματα μονο: καντε εισοδο{" "}
          <Link to="login" className="underline font-bold text-coca-cola-red">
            εδω
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Landing;
