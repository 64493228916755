function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export const formatDate = (date) => {
  var tempDate = new Date(date);
  return String(
    [
      tempDate.getFullYear(),
      padTo2Digits(tempDate.getMonth() + 1),
      padTo2Digits(tempDate.getDate()),
    ].join("-") +
      " " +
      [
        padTo2Digits(tempDate.getHours()),
        padTo2Digits(tempDate.getMinutes()),
        padTo2Digits(tempDate.getSeconds()),
      ].join(":")
  );
};

export const formatDateToDay = (date) => {
  var tempDate = new Date(date);
  return String(
    [
      tempDate.getFullYear(),
      padTo2Digits(tempDate.getMonth() + 1),
      padTo2Digits(tempDate.getDate()),
    ].join("-")
  );
};

export const formatDateToTime = (date) => {
  var tempDate = new Date(date);
  return String(
    [
      padTo2Digits(tempDate.getHours()),
      padTo2Digits(tempDate.getMinutes()),
      padTo2Digits(tempDate.getSeconds()),
    ].join(":")
  );
};

export const formatDateToMonth = (date) => {
  var tempDate = new Date(date);
  var monthName = new Intl.DateTimeFormat("el-GR", { month: "long" }).format;
  var longName = monthName(tempDate);
  return longName;
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const handleReturnToBack = (navigate) => {
  navigate(-1);
};

export const adminLogout = (navigate) => {
  sessionStorage.removeItem("adminToken");
  navigate("/ch5/login");
};

export const logout = (navigate) => {
  sessionStorage.removeItem("authToken");
  navigate("/");
};
