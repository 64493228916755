import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getMiniMarketDetails,
  getMiniMarketReceipts,
} from "../requests/miniMarkets/getData";
import { logout } from "../utils";
import ReceiptsPerMonthTable from "../components/receiptsPerMonthTable";
import ReceiptsPerDayTable from "../components/receiptsPerDayTable";
import ReceiptsTotalTable from "../components/receiptsTotalTable";

const MiniMarketDetails = ({ setIsMiniMarket }) => {
  const [details, setDetails] = useState(null);
  const [miniMarketId, setMiniMarketId] = useState(null);
  const [receiptsData, setReceiptsData] = useState(null);
  const [selectedTable, setSelectedTable] = useState("month");
  const navigate = useNavigate();

  useEffect(() => {
    getMiniMarketDetails(setDetails, setMiniMarketId, navigate);
  }, [navigate]);

  useEffect(() => {
    if (miniMarketId) {
      getMiniMarketReceipts(
        setReceiptsData,
        selectedTable,
        miniMarketId,
        navigate
      );
    }
  }, [navigate, selectedTable, miniMarketId]);

  useEffect(() => {
    setIsMiniMarket(true);
  }, [setIsMiniMarket]);

  const renderSwitch = (selected) => {
    switch (selected) {
      case "month":
        return <ReceiptsPerMonthTable receiptsPerMonthData={receiptsData} />;
      case "day":
        return <ReceiptsPerDayTable receiptsPerDayData={receiptsData} />;
      case "total":
        return <ReceiptsTotalTable receiptsTotalData={receiptsData} />;
      default:
        return <ReceiptsPerMonthTable receiptsPerMonthData={receiptsData} />;
    }
  };

  return (
    <>
      {details ? (
        <div className="flex flex-col w-full items-center space-y-4 h-full">
          <h1 className="font-black text-3xl text-center px-12 pt-10">
            αποτελεσματα καταστηματος
          </h1>
          <p className="uppercase text-center text-xs">
            τα συνολα αφορουν αριθμο καταχωρημενων αποδειξεων
          </p>
          <div className="grid grid-cols-2 grid-rows-2 items-center justify-center gap-y-1 gap-x-4 text-center px-2">
            <input
              id="total-receipts"
              disabled
              className="text-black text-xl w-full text-center bg-white shadow-md shadow-gray-300 rounded-full px-4 py-2"
              value={details.total_receipts}
            />
            <input
              id="total-receipts"
              disabled
              className="text-black text-xl w-full text-center bg-white shadow-md shadow-gray-300 rounded-full px-4 py-2"
              value={details.month_total_receipts}
            />
            <label
              htmlFor="total-receipts"
              className="w-full uppercase text-xs text-coca-cola-red font-bold"
            >
              συνολο εως
              <br />
              σημερα
            </label>
            <label
              htmlFor="total-receipts"
              className="w-full uppercase text-xs text-coca-cola-red font-bold"
            >
              συνολο τρεχοντος
              <br />
              μηνα
            </label>
          </div>
          <div className="flex flex-col w-full h-full px-6">
            <div className="w-full flex items-center justify-center border-b-2 border-b-black">
              <button
                className={
                  "w-full uppercase " +
                  (selectedTable === "month"
                    ? "bg-coca-cola-red"
                    : "bg-gray-300")
                }
                onClick={() => {
                  setSelectedTable("month");
                  setReceiptsData(null);
                }}
              >
                ανα μηνα
              </button>
              <button
                className={
                  "w-full uppercase " +
                  (selectedTable === "day" ? "bg-coca-cola-red" : "bg-gray-300")
                }
                onClick={() => {
                  setSelectedTable("day");
                  setReceiptsData(null);
                }}
              >
                ανα μερα
              </button>
              <button
                className={
                  "w-full uppercase " +
                  (selectedTable === "total"
                    ? "bg-coca-cola-red"
                    : "bg-gray-300")
                }
                onClick={() => {
                  setSelectedTable("total");
                  setReceiptsData(null);
                }}
              >
                αναλυτικα
              </button>
            </div>
            <div className="flex flex-col w-full h-full justify-between items-center space-y-4">
              {renderSwitch(selectedTable)}
              <button
                onClick={() => logout(navigate)}
                className="rounded-full bg-coca-cola-red border border-white w-min px-10 py-3 text-white font-bold text-xl uppercase"
              >
                εξοδος
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MiniMarketDetails;
