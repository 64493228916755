import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Loading from "./loading";
import { useState } from "react";

const Table = ({
  data,
  columns,
  isClickable = false,
  setSelectedWinner = null,
  emptyMsg = "",
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  const handleClickedRow = (row) => {
    if (isClickable) {
      setSelectedWinner(row.original);
    }
  };

  return (
    <>
      {data ? (
        <div className="w-full flex flex-col items-center justify-center">
          {data.length > 0 ? (
            <>
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} className="border border-black">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {data.length > 0 && (
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="odd:bg-gray-300 normal-case text-center"
                        onClick={() => handleClickedRow(row)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="border border-black">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              <div className="flex space-x-1 pt-1">
                <button
                  className="bg-coca-cola-red border border-white px-1 text-white font-bold text-lg uppercase disabled:bg-coca-cola-disabled"
                  onClick={() => table.firstPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </button>
                <button
                  className="bg-coca-cola-red border border-white px-1 text-white font-bold text-lg uppercase disabled:bg-coca-cola-disabled"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </button>
                <p className="text-md">
                  Page
                  <strong>
                    {" "}
                    {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount().toLocaleString()}
                  </strong>
                </p>

                <button
                  className="bg-coca-cola-red border border-white px-1 text-white font-bold text-lg uppercase disabled:bg-coca-cola-disabled"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </button>
                <button
                  className="bg-coca-cola-red border border-white px-1 text-white font-bold text-lg uppercase disabled:bg-coca-cola-disabled"
                  onClick={() => table.lastPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </button>
              </div>
            </>
          ) : (
            <p>{emptyMsg}</p>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Table;
