import Webcam from "react-webcam";
import { useCallback, useRef, useState } from "react";
import { RetakeIcon, CameraIcon, SubmitIcon } from "../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import { sendReicipt } from "../requests/miniMarkets/sendData";
import Footer from "../components/footer";

const CustomWebcam = ({ setWinner, setWinnerFormData }) => {
  let { uuid } = useParams();
  const navigate = useNavigate();
  var userAgent = window.navigator.userAgent;

  const webcamRef = useRef(null); // create a webcam reference
  const [imgSrc, setImgSrc] = useState(null); // initialize it

  const videoConstraints = {
    facingMode: { ideal: "environment" },
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const handleCameraClick = () => {
    capture();
  };

  const handleSubmit = () => {
    sendReicipt(uuid, navigate, setWinner, setWinnerFormData, imgSrc);
  };

  const handleBack = () => {
    setImgSrc(null);
  };

  return (
    <div
      className={
        "w-screen relative flex flex-col justify-between overflow-y-auto font-tccc uppercase bg-coca-cola-light-gray items-center " +
        (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)
          ? "h-dvh"
          : "h-screen")
      }
    >
      <div className="flex flex-col flex-1 items-center justify-center space-y-3 uppercase font-tccc text-center md:max-w-screen-md">
        {imgSrc ? (
          <div className="w-full h-full flex items-center bg-black">
            <img src={imgSrc} alt="webcam" className="w-full" />
          </div>
        ) : (
          <Webcam
            className="w-full h-full bg-black"
            videoConstraints={videoConstraints}
            ref={webcamRef}
          />
        )}
        <div className="flex flex-col h-max items-center justify-center space-y-6 mx-6">
          <h1 className="font-black text-xl">φωτογραφισε την αποδειξη</h1>
          <div className="flex space-x-4 h-20">
            {imgSrc ? (
              <>
                <button onClick={handleSubmit}>
                  <SubmitIcon />
                </button>
                <button onClick={handleBack}>
                  <RetakeIcon />
                </button>
              </>
            ) : (
              <button
                onClick={handleCameraClick}
                className="rounded-full border-2 border-black h-max"
              >
                <CameraIcon />
              </button>
            )}
          </div>
          <p className="normal-case text-sm">
            Σας προτρέπουμε να ανεβάσετε την απόδειξη κατόπιν απαλοιφής (π.χ.
            χειρόγραφης διαγραφής) στοιχείων αξίας και των λοιπών μη σχετικών με
            τον διαγωνισμό προϊόντων.
          </p>
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default CustomWebcam;
