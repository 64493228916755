import { useEffect, useState } from "react";
import { validateClientId } from "../requests/miniMarkets/validation";
import { activateQR } from "../requests/miniMarkets/sendData";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PasswordInput } from "../components/passwordInput";
import Input from "../components/input";
import { ErrAlert } from "../components/alerts";

const ActivateQR = ({ setIsMiniMarket }) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [clientId, setClientId] = useState("");
  const [password, setPassword] = useState("");
  const [validationPassword, setValidationPassword] = useState("");
  const [err, setErr] = useState("");
  const [isValid, setIsValid] = useState(false);
  let { uuid } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setIsMiniMarket(true);
  }, [setIsMiniMarket]);

  const handleClientIdSubmit = (e) => {
    e.preventDefault();
    validateClientId(clientId, setIsValid, setErr);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password !== validationPassword) {
      setErr("οι κωδικοι δεν ειναι ιδιοι");
    } else {
      activateQR(uuid, clientId, password, setErr, navigate);
    }
  };

  return (
    <>
      <p
        className={
          "font-bold pt-8 text-center px-10 " +
          (acceptedTerms && !isValid
            ? "text-coca-cola-red"
            : "text-transparent")
        }
      >
        ο κωδικος qr δεν ειναι ενεργος.
      </p>
      <h1
        className={
          "font-black text-2xl text-center px-12 " +
          (acceptedTerms ? "text-black" : "text-coca-cola-disabled")
        }
      >
        ενεργοποιησε τη συμμετοχη καταστηματος
      </h1>
      <p
        className={
          "font-bold text-xl pb-4 text-center px-10 " +
          (acceptedTerms ? "text-coca-cola-red" : "text-transparent")
        }
      >
        βημα {isValid ? "2" : "1"} απο 2
      </p>
      {!isValid ? (
        <form
          onSubmit={handleClientIdSubmit}
          className="flex flex-col space-y-4 w-full items-center justify-end px-12"
          autoComplete="off"
        >
          <div className="flex flex-col items-center">
            <label
              className={
                "text-black text-sm w-full text-center " +
                (acceptedTerms ? "text-black" : "text-coca-cola-disabled")
              }
              htmlFor="clientId"
            >
              κωδικος πελατη:
            </label>
            <Input
              id="clientId"
              value={clientId}
              onChange={setClientId}
              err={err}
              setErr={setErr}
              disabled={!acceptedTerms}
              required={true}
            />
          </div>
          <p
            className={
              "normal-case font-bold text-center text-sm pb-6 " +
              (acceptedTerms ? "text-black" : "text-coca-cola-disabled")
            }
          >
            Αποδέξου τους όρους και συμπλήρωσε τον κωδικό πελάτη που σου έδωσε ο
            συνεργάτης της 3Ε για να ενεργοποιήσεις το QR
          </p>
          <button
            type="submit"
            disabled={!acceptedTerms}
            className="rounded-full bg-coca-cola-red border border-white px-10 py-3 text-white font-bold text-xl uppercase disabled:bg-coca-cola-disabled"
          >
            επομενο
          </button>
          <div className="flex items-center justify-center space-x-2">
            <input
              id="terms"
              className="accent-coca-cola-red"
              checked={acceptedTerms}
              onChange={() => setAcceptedTerms(!acceptedTerms)}
              type="checkbox"
            />
            <label htmlFor="terms">
              αποδοχη των{" "}
              <Link
                className="underline text-coca-cola-red"
                to="/terms-and-conditions"
              >
                ορων
              </Link>
            </label>
          </div>
          {err && <ErrAlert err={err} setErr={setErr} />}
        </form>
      ) : (
        <form
          onSubmit={handlePasswordSubmit}
          className="flex flex-col space-y-4 w-full items-center px-12"
        >
          <div className="flex flex-col items-center space-y-1">
            <label className="w-full text-center" htmlFor="password">
              ορισε password συνδεσης:
            </label>
            <PasswordInput
              id="password"
              password={password}
              setPassword={setPassword}
              err={err}
              setErr={setErr}
            />
            <label className="w-full text-center" htmlFor="passwordValidation">
              επαληθευσε password συνδεσης:
            </label>
            <PasswordInput
              id="validationPassword"
              password={validationPassword}
              setPassword={setValidationPassword}
              err={err}
              setErr={setErr}
            />
          </div>
          <button
            type="submit"
            className="rounded-full bg-coca-cola-red border border-white px-4 py-3 text-white font-bold text-xl uppercase disabled:bg-coca-cola-disabled"
          >
            ενεργοποιηση
          </button>
          {err && <ErrAlert err={err} setErr={setErr} />}
        </form>
      )}
    </>
  );
};

export default ActivateQR;
