import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "./pages/Landing";
import Scanned from "./pages/Scanned";
import RequestAccess from "./pages/RequestAccess";
import CustomWebcam from "./pages/CustomWebcam";
import Final from "./pages/Final";
import ActivateQR from "./pages/ActivateQR";
import QRGuard from "./guards/QRGuard";
import { useState } from "react";
import MiniMarketDetails from "./pages/MiniMarketDetails";
import PageLayout from "./layouts/PageLayout";
import FooterLinksLayout from "./layouts/FooterLinksLayout";
import MiniMarketLogin from "./pages/MiniMarketLogin";
import AuthGuard from "./guards/AuthGuard";
import AdminLogin from "./pages/AdminLogin";
import AdminAuthGuard from "./guards/AdminAuthGuard";
import AdminHome from "./pages/AdminHome";

function App() {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [winner, setWinner] = useState(false);
  const [winnerFormData, setWinnerFormData] = useState(null);
  const [isMiniMarket, setIsMiniMarket] = useState(false);

  return (
    <Routes>
      <Route path="/" element={<PageLayout isMiniMarket={isMiniMarket} />}>
        <Route
          index={true}
          element={<Landing setIsMiniMarket={setIsMiniMarket} />}
        />
        <Route
          path="qr/:uuid"
          element={<QRGuard acceptedTerms={acceptedTerms} />}
        >
          <Route
            index={true}
            element={
              <Scanned
                acceptedTerms={acceptedTerms}
                setAcceptedTerms={setAcceptedTerms}
                setIsMiniMarket={setIsMiniMarket}
              />
            }
          />
          <Route
            path="activate"
            element={<ActivateQR setIsMiniMarket={setIsMiniMarket} />}
          />
          <Route path="access" element={<RequestAccess />} />
        </Route>
        <Route path="/" element={<AuthGuard />}>
          <Route
            path="mini-market"
            element={<MiniMarketDetails setIsMiniMarket={setIsMiniMarket} />}
          />
        </Route>
        <Route
          path="login"
          element={<MiniMarketLogin setIsMiniMarket={setIsMiniMarket} />}
        />
        <Route
          path="qr?/:uuid?/terms-and-conditions"
          element={
            <FooterLinksLayout
              component={"terms"}
              isMiniMarket={isMiniMarket}
            />
          }
        />
        <Route
          path="qr/:uuid/participation-terms"
          element={<FooterLinksLayout component={"participation"} />}
        />
        <Route
          path="qr?/:uuid?/contact"
          element={<FooterLinksLayout component={"contact"} />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route
        path="qr/:uuid"
        element={<QRGuard acceptedTerms={acceptedTerms} />}
      >
        <Route
          path="camera"
          element={
            <CustomWebcam
              setWinner={setWinner}
              setWinnerFormData={setWinnerFormData}
            />
          }
        />
        <Route
          path="final"
          element={
            <Final
              winner={winner}
              setAcceptedTerms={setAcceptedTerms}
              winnerFormData={winnerFormData}
              setWinnerFormData={setWinnerFormData}
            />
          }
        />
      </Route>
      <Route path="ch5">
        <Route path="login" element={<AdminLogin />} />
      </Route>
      <Route path="ch5" element={<AdminHome />}>
        <Route index={true} element={<AdminAuthGuard />} />
      </Route>
    </Routes>
  );
}

export default App;
