const UserTerms = () => {
  return (
    <div className="flex flex-col space-y-2 items-center normal-case px-8 py-4">
      <h1 className="uppercase text-2xl font-black text-center">
        ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ ΧΡΗΣΗΣ
      </h1>
      <h2 className="font-bold text-sm text-center italic">
        ΠΑΡΑΚΑΛΟΥΜΕ ΔΙΑΒΑΣΤΕ ΠΡΟΣΕΚΤΙΚΑ ΤΟΥΣ ΠΑΡΑΚΑΤΩ ΟΡΟΥΣ ΚΑΙ ΠΡΟΥΠΟΘΕΣΕΙΣ
        ΠΡΙΝ ΚΑΝΕΤΕ ΧΡΗΣΗ ΤΗΣ ΠΑΡΟΥΣΑΣ ΙΣΤΟΣΕΛΙΔΑΣ
      </h2>
      <div className="w-full flex flex-col space-y-2 text-justify">
        <p className="font-bold">ΟΡΟΙ ΧΡΗΣΗΣ ΙΣΤΟΣΕΛΙΔΑΣ</p>
        <p>
          Σας ευχαριστούμε που επισκέπτεσθε αυτή την ιστοσελίδα. Η πρόσβαση και
          η χρήση της παρούσας ιστοσελίδας («Τοποθεσία» ή «Ιστότοπος»)
          υπόκεινται στους ακόλουθους όρους χρήσης και σε όλους τους
          εφαρμοστέους νόμους και κανονισμούς. Μέσω της πρόσβασης και χρήσης
          αυτής της Τοποθεσίας, αποδέχεστε πλήρως τους όρους χρήσης και
          αποδέχεστε ότι κάθε άλλη συμφωνία μεταξύ υμών και της Chapter 5 ΑΕ
          αντικαθίσταται από το παρόν σε ό,τι αφορά το περιεχόμενο του παρόντος.
          Εάν δεν αποδέχεστε πλήρως τους όρους χρήσης, παρακαλούμε να
          αποχωρήσετε από την παρούσα Τοποθεσία.
        </p>
        <p className="font-bold">
          Καθεστώς ιδιοκτησίας του Ιστότοπου και του Περιεχομένου:
        </p>
        <p>
          1. Η Τοποθεσία ανήκει στην Chapter 5 AE και όλα τα περιεχόμενά της στα
          οποία περιλαμβάνονται ενδεικτικά, το κείμενο, το σχέδιο, τα λογότυπα,
          τα γραφικά, το λογισμικό οι εφαρμογές, τα εικονίδια και οι εικόνες,
          (το «Περιεχόμενο»), προστατεύονται από δικαιώματα πνευματικής και
          βιομηχανικής ιδιοκτησίας της{" "}
          <span className="font-bold">Coca-Cola 3Ε ΕΛΛΑΔΟΣ ΑΒΕΕ</span>, ή τρίτων
          με την επιφύλαξη παντός δικαιώματος, εάν δεν αναφέρεται κάτι
          διαφορετικό. Επίσης ο Ιστότοπος προστατεύεται με βάση τους κανόνες της
          πνευματικής ιδιοκτησίας.
        </p>
        <p>
          2. Περαιτέρω αναγνωρίζετε ότι η The Coca-Cola Company είναι ο
          αποκλειστικός δικαιούχος των (καταχωρισμένων ή μη) σημάτων”
          “Coca-Cola” καθώς και όλων των εμπορικών ονομασιών, διακριτικών
          γνωρισμάτων, διασχηματισμών και εμπορικών επωνυμιών που συνδέονται με
          τις λέξεις Coca-Cola. Στον Ιστότοπο μπορεί να εμφανίζονται και άλλα
          εμπορικά σήματα της{" "}
          <span className="font-bold">Coca-Cola 3Ε ΕΛΛΑΔΟΣ ΑΒΕΕ</span>, της Coca
          Cola Services NV ή/και της The Coca Cola Company ή και τρίτων
          δικαιούχων, τα οποία παραμένουν στην αποκλειστική κυριότητα αυτών των
          δικαιούχων.
        </p>
        <p>
          3. Απαγορεύεται αυστηρά η χρήση οποιουδήποτε Περιεχομένου, εκτός αυτού
          που προβλέπεται από τους παρόντες όρους χρήσης, χωρίς έγγραφη άδεια
          της Chapter 5 AE. Η Chapter 5 AE, η{" "}
          <span className="font-bold">Coca-Cola 3Ε ΕΛΛΑΔΟΣ ΑΒΕΕ</span>, η Coca
          Cola Services NV και η The Coca Cola Company θα κάνουν πλήρη χρήση των
          ένδικων μέσων που τους παρέχουν τα πνευματικά δικαιώματα όπως
          προβλέπεται από το νόμο, συμπεριλαμβανομένου και αυτού της ποινικής
          δίωξης.
        </p>
        <p>
          4. Με την είσοδο του χρήστη στον ιστότοπο, χορηγείται σε αυτόν ένα μη
          αποκλειστικό, αμεταβίβαστο, προσωπικό, περιορισμένο δικαίωμα
          πρόσβασης, χρήσης αυτής της ιστοσελίδας και των περιεχομένων στοιχείων
          της. Η χορηγηθείσα άδεια δεν συνιστά σε καμία περίπτωση μεταβίβαση
          τίτλου στην ιστοσελίδα και στα στοιχεία της και χορηγείται
          αποκλειστικά για προσωπική, μη εμπορική χρήση του ιστοτόπου.
        </p>
        <p>
          5. Η χορηγηθείσα άδεια δεν επιτρέπει την οποιαδήποτε πράξη μεταπώλησης
          ή εμπορικής χρήσης, αναπαραγωγής, δημιουργίας αντιγράφων, μετάδοσης,
          ανακοίνωσης, θέσης σε κυκλοφορία, τροποποίησης ή εκχώρησης άδειας ή
          άλλο τρόπο εκμετάλλευσης τoυ ιστοτόπου ή των επιμέρους στοιχείων του
          περιεχομένου του, οποιαδήποτε συλλογή και χρήση των στοιχείων του
          περιεχομένου του, οποιαδήποτε λήψη ή αντιγραφή πληροφοριών λογαριασμών
          προς όφελος άλλου εμπόρου ή οποιαδήποτε χρήση εργαλείων συλλογής και
          εξαγωγής δεδομένων.
        </p>
        <p>
          6. Οποιαδήποτε μη εξουσιοδοτημένη χρήση από τον χρήστη του ιστοτόπου ή
          και επιμέρους στοιχείων του περιεχομένου του, συνεπάγεται αυτομάτως
          τερματισμό της χορηγηθείσας περιορισμένης άδειας. Η Εταιρεία
          επιφυλάσσεται παντός νομίμου δικαιώματός της για την άσκηση ένδικων
          βοηθημάτων έναντι του χρήστη που θα προβεί σε πράξεις μη
          εξουσιοδοτημένης χρήσης του ιστοτόπου, σύμφωνα με τα προβλεπόμενα στην
          ισχύουσα νομοθεσία
        </p>
        <p className="font-bold">Εμπορικά σήματα:</p>
        <p>
          7. Το περιεχόμενο του παρόντος ιστοτόπου καθώς και των επιμέρους
          στοιχείων του, συμπεριλαμβανομένων ενδεικτικώς κειμένων, φωτογραφιών,
          λογότυπων, γραφικών, εμπορικών σημάτων κλπ, αποτελούν πνευματική
          ιδιοκτησία της Εταιρείας ή των δικαιοπαρόχων των προμηθευτών και των
          πελατών της Εταιρείας, στην οποία έχει παραχωρηθεί το δικαίωμα να
          χρησιμοποιεί τα ως άνω σήματα και στοιχεία και προστατεύονται από την
          ελληνική και κοινοτική νομοθεσία περί προστασίας των δικαιωμάτων της
          πνευματικής ιδιοκτησίας καθώς και της προστασίας των εμπορικών
          σημάτων.
        </p>
        <p>
          8. Απαγορεύεται ρητώς στους χρήστες η οποιαδήποτε ενέργεια αντιγραφής,
          τροποποίησης, αλλοίωσης, διαμόρφωσης, αναπαραγωγής, διανομής,
          μεταβίβασης, πώλησης, αναδημοσίευσης, αποστολής δεδομένων με
          οποιοδήποτε τρόπο και οποιασδήποτε πληροφορίας παρέχεται στο
          συγκεκριμένο διαδικτυακό τόπο με οποιοδήποτε μέσο ή τρόπο χωρίς την
          προηγούμενη έγγραφη άδεια της Εταιρείας. Σε περίπτωση χρήσης,
          αντιγραφής ή μετατροπής των Σημάτων χωρίς προηγούμενη γραπτή άδεια, η
          εταιρεία έχει το δικαίωμα να προβεί σε νομικές ή άλλες ενέργειες
          προκειμένου για την προστασία των συμφερόντων της.
        </p>
        <p className="font-bold">Η χρήση της Τοποθεσίας</p>
        <p>
          9.Η <span className="font-bold">Chapter 5 AE</span> σάς χορηγεί την
          άδεια χρήσης της Τοποθεσίας με τον ακόλουθο τρόπο:
        </p>
        <p>
          10. Επιτρέπεται η λήψη Περιεχομένου, αλλά μόνο με σκοπό την προσωπική
          πληροφόρηση και όχι για εμπορικούς και κερδοσκοπικούς σκοπούς, με την
          προϋπόθεση ότι δεν θα προβείτε σε απομάκρυνση, αλλαγή ή συγκάλυψη των
          πληροφοριών, του περιεχομένου ή των δικαιωμάτων (πνευματικής
          ιδιοκτησίας κι άλλων ιδιοκτησιακών δικαιωμάτων) που εμπεριέχει το
          Περιεχόμενο.
        </p>
        <p>
          11. Απαγορεύεται η διανομή, η τροποποίηση, η αντιγραφή (εκτός εάν
          προβλέπεται από τα παραπάνω), η μετάδοση, η εμφάνιση, η εκ νέου χρήση,
          η αναπαραγωγή, η δημοσίευση, η χορήγηση άδειας, η μεταβίβαση, πώληση ή
          άλλη χρήση του Περιεχομένου, καθώς και η δημιουργία παράγωγων έργων
          χωρίς την έγγραφη άδεια της{" "}
          <span className="font-bold">Chapter 5 AE</span>.
        </p>
        <p>
          12. Ως όρο για την πρόσβαση και χρήση της Τοποθεσίας, δεσμεύεστε προς
          την <span className="font-bold">Chapter 5 AE</span> ότι δεν θα
          χρησιμοποιήσετε την Τοποθεσία με οποιοδήποτε σκοπό, ο οποίος
          απαγορεύεται από τους όρους χρήσης ή με τρόπο που θα μπορούσε να
          συνιστά ή να ενθαρρύνει συμπεριφορά που θα μπορούσε να θεωρηθεί
          ποινικό αδίκημα, να εγείρει αστική ευθύνη ή να παραβιάσει με άλλο
          τρόπο οποιοδήποτε νόμο, καθώς και ότι δε θα χρησιμοποιήσετε την
          Τοποθεσία για τη δημοσίευση ή τη μετάδοση οποιουδήποτε υλικού με
          απειλητικό, αναληθές, παραπλανητικό, προσβλητικό, παρενοχλητικό,
          συκοφαντικό, χυδαίο, ανήθικο, σκανδαλώδες, πορνογραφικό ή βλάσφημο
          περιεχόμενο. Η <span className="font-bold">Chapter 5 AE</span> θα
          συνεργαστεί πλήρως με όλες τις αρχές επιβολής του νόμου και θα
          συμμορφωθεί πλήρως με οποιαδήποτε δικαστική απόφαση που θα ζητά ή θα
          δίνει εντολή για την αποκάλυψη της ταυτότητας οποιουδήποτε ατόμου
          δημοσιεύει ή μεταδίδει τέτοιου είδους πληροφορίες και υλικό.
          Δεσμεύεστε επίσης ότι θα πληροφορήσετε την{" "}
          <span className="font-bold">Chapter 5 AE</span>, αμέσως μόλις
          αντιληφθείτε κάθε παράνομη ή απαγορευμένη χρήση της Τοποθεσίας από
          τρίτους.
        </p>
        <p>
          13. Φέρετε ευθύνη για τη συμμόρφωση με την εγχώρια νομοθεσία και τους
          κανονισμούς της δικαιοδοσίας του τόπου από όπου έχετε πρόσβαση στην
          Τοποθεσία. Δεν επιτρέπεται η πρόσβαση στην Τοποθεσία σε οποιαδήποτε
          δικαιοδοσία όπου για οποιονδήποτε λόγο απαγορεύεται η δημοσίευση ή η
          διαθεσιμότητα της Τοποθεσίας.{" "}
        </p>
        <p className="font-bold">Ειδικότερα:</p>
        <p>
          13.1. Ο χρήστης οφείλει να χρησιμοποιεί την ιστοσελίδα με σεβασμό στα
          δικαιώματα των υπολοίπων χρηστών. Ως εκ τούτου, ο χρήστης που
          εισέρχεται στην ιστοσελίδα απαγορεύεται να:
        </p>
        <div className="w-full px-4">
          <p>
            - ανεβάσει, ποστάρει, στείλει με e-mail ή μεταδώσει με οποιοδήποτε
            άλλο τρόπο περιεχόμενο που μπορεί να θεωρηθεί παράνομο, επιβλαβές,
            απειλητικό, προσβλητικό, ενοχλητικό, επιζήμιο, δυσφημιστικό, χυδαίο,
            άσεμνο, απεχθές, ρατσιστικό ή που προσβάλλει την ιδιωτικότητα άλλου,
            συμπεριλαμβανομένου του δικαιώματός του για την προσωπική του εικόνα
            ή αποδοκιμαστέο με οποιονδήποτε άλλο τρόπο.
          </p>
          <p>
            - ανεβάσει, ποστάρει, στείλει με e-mail ή μεταδώσει με οποιονδήποτε
            άλλο τρόπο κάθε είδους διαφήμιση, προωθητικό υλικό, «junk mail»,
            «spam», «chain letters» ή κάθε άλλου είδους ενοχλητικό υλικό.
          </p>
          <p>
            - ανεβάσει, ποστάρει, στείλει με e-mail ή μεταδώσει με οποιονδήποτε
            άλλο τρόπο οποιοδήποτε υλικό περιέχει ιούς ή κάθε είδους ηλεκτρονικό
            κώδικα, αρχεία ή προγράμματα που έχουν σχεδιαστεί να διακόπτουν, να
            καταστρέφουν ή να περιορίζουν την λειτουργικότητα λογισμικού,
            εξοπλισμού ή δικτύου τηλεπικοινωνίας.
          </p>
          <p>
            - προσπαθήσει να μειώσει την εξυπηρέτηση του χρήστη, του παρόχου ή
            του δικτύου, εκθέτοντας την ιστοσελίδα σε ιό, δημιουργώντας κορεσμό,
            «πλημυρίζοντας» το διακομιστή, ή τοποθετώντας την κεφαλίδα ενός
            πακέτου TCP-IP ή κάθε μέρος της πληροφορίας κεφαλίδας σε ένα e-mail.
          </p>
          <p>
            - προσπαθήσει να μειώσει την εξυπηρέτηση του χρήστη, του παρόχου ή
            του δικτύου, εκθέτοντας την ιστοσελίδα σε ιό, δημιουργώντας κορεσμό,
            «πλημυρίζοντας» το διακομιστή, ή τοποθετώντας την κεφαλίδα ενός
            πακέτου TCP-IP ή κάθε μέρος της πληροφορίας κεφαλίδας σε ένα e-mail.
          </p>
          <p>
            - επιχειρήσει να διερευνήσει ή να δοκιμάσει την ευπάθεια του
            συστήματος ή του δικτύου ή παραβιάσει την ασφάλεια ή τα μέτρα
            ταυτοποίησης χωρίς πρότερη εξουσιοδότηση.
          </p>
          <p>- χρησιμοποιήσει ταυτότητα άλλου χρήστη.</p>
          <p>
            - αναλάβει οποιαδήποτε δραστηριότητα ή υποκινήσει κάποιο άλλο
            πρόσωπο σε παράνομη ή άλλου είδους δραστηριότητα που προσβάλει τα
            δικαιώματα της Εταιρείας, των προμηθευτών της, των εταίρων και
            διανομέων της, των διαφημιστών της και κάθε άλλου χρήστη.
          </p>
          <p>
            - μεταφέρει και μεταδώσει οποιαδήποτε πληροφορία ή λογισμικό που
            δημιουργήθηκε από την ιστοσελίδα συγκεκριμένα για τρίτες χώρες ή για
            αλλοδαπούς και παραβιάσει έτσι την εθνική ή διεθνή νομοθεσία.
          </p>
          <p>
            - παρέχει ψευδείς, παραπλανητικές ή ανακριβείς πληροφορίες στην
            Εταιρεία ή σε κάθε σχετιζόμενο με την Εταιρεία πρόσωπο.
          </p>
          <p>
            - εξατομικεύσει λογαριασμό άλλου χρήστη ή παρουσιάσει ψευδώς ή
            παραπλανητικά ή ανακριβώς σχέση του με άλλο φυσικό ή νομικό πρόσωπο.
          </p>
        </div>
        <p>
          13.2. Ο χρήστης συμφωνεί να συμμορφώνεται με όλους τους εθνικούς και
          διεθνείς κανονισμούς σχετικά με την συμπεριφορά του στο διαδίκτυο και
          το αποδεκτό περιεχόμενο, συμπεριλαμβανομένης της εφαρμοζόμενης
          νομοθεσίας σχετικά με τη μετάδοση τεχνικών στοιχείων.
        </p>
        <p>
          13.3. Ανά πάσα στιγμή και για οποιοδήποτε λόγο, η Εταιρεία μπορεί να
          κινήσει όλες τις δυνατές διαδικασίες και να τερματίσει την χρήση της
          ιστοσελίδας σε κάποιο χρήστη, χωρίς προηγούμενη ειδοποίηση ή την χρήση
          μιας ή περισσοτέρων υπηρεσιών της ιστοσελίδας για κάθε αιτία που
          παραβιάζει τους παρόντες Όρους και Προϋποθέσεις. Η Εταιρεία διατηρεί
          με κάθε επιφύλαξη το δικαίωμα διεκδίκησης αποζημίωσης από τον χρήστη
          για τυχόν ζημιές ή βλάβες που θα υποστεί, ως αποτέλεσμα μη συμμόρφωσης
          του με τους Όρους και Προϋποθέσεις της παρούσας ιστοσελίδας.
        </p>
        <p className="font-bold">Αποποίηση Ευθύνης:</p>
        <p>
          14.1. Η Εταιρεία καταβάλλει κάθε δυνατή προσπάθεια ώστε να διατηρεί
          ασφαλές το περιβάλλον της ιστοσελίδας της. Παρόλα αυτά η Εταιρεία δεν
          παρέχει καμία εγγύηση ότι η ιστοσελίδα δεν φέρει ιούς ή/ και σφάλματα.
        </p>
        <p>
          14.2. Η Εταιρεία δεν φέρει ουδεμία ευθύνη στην περίπτωση που για
          οποιονδήποτε λόγο, συμπεριλαμβανομένης και της περίπτωσης αμέλειας,
          διακοπεί η λειτουργία του ιστοτόπου ή καθίσταται δυσχερής ή/ και
          αδύνατη η πρόσβαση σε αυτό ή/ εάν τρίτα μη εξουσιοδοτημένα πρόσωπα
          παρέμβουν καθ’ οιονδήποτε τρόπο στο περιεχόμενο και στην λειτουργία
          του ιστοτόπου καθιστώντας δυσχερή την χρήση του ή προκαλώντας
          προβλήματα στην ορθή λειτουργία αυτού ή υποκλέπτοντας στοιχεία που
          αφορούν προσωπικά δεδομένα των χρηστών.
        </p>
        <p>
          14.3. Η Εταιρεία δεν ευθύνεται σε περίπτωση διακοπής πρόσβασης στον
          ιστότοπο για λόγους που εκφεύγουν της σφαίρας επιρροής της, καθώς και
          για λόγους που οφείλονται σε τεχνική ή άλλη αδυναμία του δικτύου ή σε
          λόγους ανωτέρας βίας ή σε τυχηρά γεγονότα.
        </p>
        <p>
          14.4. Η Εταιρεία δεν φέρει ευθύνη για τυχόν απώλεια ή ζημιά που μπορεί
          να προκληθεί από επίθεση που αφορά άρνηση υπηρεσίας, ιούς ή άλλο
          τεχνολογικά επιβλαβές υλικό που μπορεί να μολύνει το υλικό, το
          λογισμικό, τα δεδομένα ή άλλο ιδιοκτησιακό υλικό των χρηστών λόγω της
          χρήσης της παρούσας ιστοσελίδας ή της λήψης οποιουδήποτε υλικού έχει
          αναρτηθεί στην παρούσα ή σε οποιαδήποτε ιστοσελίδα συνδεδεμένη με
          αυτή.
        </p>
        <p>
          14.5. Η χρήση της Τοποθεσίας γίνεται με δικό σας κίνδυνο. Τόσο η{" "}
          <span className="font-bold">Chapter 5 AE</span> όσο και οι υπάλληλοι
          και τα διευθύνοντα στελέχη της, ή οποιοσδήποτε τρίτος που εμπλέκεται
          στη δημιουργία, την παραγωγή ή τη δημοσίευση της Τοποθεσίας δεν φέρουν
          ευθύνη για οποιαδήποτε άμεση, έμμεση, ηθική, θετική, αποθετική,
          μελλοντική ή άλλου είδους ζημιά που προέρχεται από ή συνδέεται με τη
          χρήση της Τοποθεσίας ή του περιεχομένου, είτε βασίζονται σε συμβατικές
          υποχρεώσεις, αδικοπραξία (συμπεριλαμβανομένης και της αμέλειας), είτε
          αφορά πλήρη ευθύνη ή άλλες, ακόμα και εάν η πιθανότητα τέτοιας βλάβης
          έχει γίνει γνωστή.
        </p>
        <p>
          15. Το Περιεχόμενο της Τοποθεσίας ενδέχεται να περιέχει ανακρίβειες
          και τυπογραφικά λάθη. Αλλαγές μπορούν να πραγματοποιούνται σε τακτά
          χρονικά διαστήματα στην Τοποθεσία ανά πάσα στιγμή και χωρίς
          ειδοποίηση. Η <span className="font-bold">Chapter 5 AE</span> ωστόσο,
          δεν δεσμεύεται για την ανά τακτά διαστήματα ανανέωση των πληροφοριών
          που περιέχονται στην Τοποθεσία. Επιπρόσθετα, η{" "}
          <span className="font-bold">Chapter 5 AE</span> δεν εγγυάται ότι η
          Τοποθεσία θα λειτουργεί συνεχώς και χωρίς σφάλματα, ότι οι ελλείψεις
          θα διορθώνονται περιοδικά ή ότι θα είναι συμβατή με τον υπολογιστή, το
          υλικό και το λογισμικό του χρήστη.
        </p>
        <p>
          16. Πληροφορίες, συμβουλές και απόψεις που εκφράζονται στην Τοποθεσία
          δεν πρέπει να λαμβάνονται ως βάση για τη λήψη προσωπικών, νομικών,
          οικονομικών και άλλου τύπου αποφάσεων. Πρέπει να συμβουλευτείτε έναν
          κατάλληλο επαγγελματία για να λάβετε συγκεκριμένες συμβουλές που θα
          ταιριάζουν στη δική σας περίπτωση. Το Περιεχόμενο υπόκειται σε αλλαγή
          και σας δίδεται «ως έχει» χωρίς καμία εγγύηση οποιασδήποτε μορφής,
          ρητή ή σιωπηρή, συμπεριλαμβανομένων, ενδεικτικά, των σιωπηρών
          εγγυήσεων ακαταλληλότητας, εμπορευσιμότητας, καταλληλόλητας για ένα
          συγκεκριμένο σκοπό ή της μη παραβίασης. χωρίς κανένα περιορισμό των
          προαναφερθέντων, η <span className="font-bold">Chapter 5 AE</span> δεν
          εγγυάται ούτε δηλώνει ότι η χρήση του περιεχόμενου από εσάς δε θα
          καταπατά τα δικαιώματα τρίτων ούτε ότι το περιεχόμενο θα είναι
          ακριβές, πλήρες και ενημερωμένο.
        </p>
        <p>
          17. Η <span className="font-bold">Chapter 5 AE</span> δεν αναλαμβάνει
          καμία ευθύνη για την αδυναμία των υπηρεσιών που προσφέρονται στην
          παρούσα.
        </p>
        <p>
          18. Εάν είστε δυσαρεστημένοι με κάποιο τμήμα της Τοποθεσίας, η
          μοναδική σας αξίωση εξαντλείται στη διακοπή χρήσης της.
        </p>
        <p className="font-bold">Σύνδεσμοι προς άλλες τοποθεσίες:</p>
        <p>
          19. Η Τοποθεσία περιέχει συνδέσμους σε άλλους ιστότοπους, που ανήκουν
          ή λειτουργούν υπό την επίβλεψη τρίτων. Οι ιστοσελίδες αυτές δεν έχουν
          καμία σχέση με την Εταιρεία και η Εταιρεία δεν φέρει καμία ευθύνη
          αναφορικά με το περιεχόμενό τους ή τυχόν απώλεια ή ζημιά που μπορεί να
          προκληθεί στο χρήστη εξαιτίας ή με αφορμή τη χρήση τους καθώς και τις
          διαδικασίες συλλογής και επεξεργασίας των προσωπικών δεδομένων του
          χρήστη, που ακολουθούνται από τους διαχειριστές αυτών των ιστοσελίδων.
          Δεν φέρουμε ευθύνη και δεν προωθούμε ή υποστηρίζουμε με οποιοδήποτε
          τρόπο τη χρήση αυτών των ιστότοπων εκτός της{" "}
          <span className="font-bold">Chapter 5 AE</span>. Οι σύνδεσμοι αυτοί
          παρέχονται μόνο για δική σας ενημέρωση και, εφόσον επιλεγούν από εσάς,
          θα σας οδηγούν σε άλλους ιστότοπους μόνο με δική σας ευθύνη. Αποτελεί
          αποκλειστική σας ευθύνη η ενημέρωσή σας για τους όρους χρήσης αυτών
          των ιστότοπων πριν πλοηγηθείτε σε αυτούς. Η{" "}
          <span className="font-bold">Chapter 5 AE</span> δεν ελέγχει και δεν
          είναι υπεύθυνη για την λειτουργία τους, το περιεχόμενό τους, τις
          πολιτικές απόρρητου που ακολουθούν ή την ασφάλεια αυτών των
          τοποθεσιών.
        </p>
        <p className="font-bold">Διαθέσιμο λογισμικό σε αυτή την Τοποθεσία:</p>
        <p>
          20. Τα δικαιώματα πνευματικής ιδιοκτησίας ή άλλα δικαιώματα επί του
          λογισμικού που είναι διαθέσιμο για λήψη από την Τοποθεσία ανήκουν στην{" "}
          <span className="font-bold">Chapter 5 AE</span> ή στους παρόχους /
          αδειοδότες αυτής.
        </p>
        <p className="font-bold">Αποζημίωση:</p>
        <p>
          21. Στο μέτρο που δεν απαγορεύεται από την εφαρμοστέα νομοθεσία,
          συμφωνείτε να αποκαταστήσετε κάθε ζημία της{" "}
          <span className="font-bold">Chapter 5 AE</span> και των συνδεδεμένων
          με αυτήν εταιριών που προκύπτει, μεταξύ άλλων, από την χρήση αυτού του
          Ιστότοπου, την παραβίαση των παρόντων όρων Χρήσης ή την παραβίαση
          δικαιωμάτων τρίτων.
        </p>
        <p className="font-bold">Αναθεώρηση των όρων:</p>
        <p>
          22. Η <span className="font-bold">Chapter 5 AE</span> μπορεί
          οποιαδήποτε στιγμή και χωρίς ειδοποίηση, να αναθεωρήσει τους παρόντες
          όρους με την ανανέωση αυτού του κειμένου. Δεσμεύεστε από τέτοιες
          αναθεωρήσεις και πρέπει, ως εκ τούτου, να επισκέπτεστε περιοδικά αυτή
          τη σελίδα για να εξετάζετε τους πρόσφατους όρους χρήσης. Δεν είναι
          δυνατή η αλλαγή των όρων χρήσης από εσάς.
        </p>
        <p className="font-bold">Γενικά:</p>
        <p>
          23. Οι όροι χρήσης και η χρήση της Τοποθεσίας διέπονται από τους
          νόμους της Ελλάδας ανεξάρτητα από την επιλογή των διατάξεων του νόμου.{" "}
        </p>
        <p>
          24. Εάν η <span className="font-bold">Chapter 5 AE</span> αποφασίσει
          να αποποιηθεί οποιαδήποτε αθέτηση δέσμευσης που απορρέει από τους
          όρους χρήσης, τότε τούτο δεν σημαίνει ότι αποποιείται και οποιαδήποτε
          άλλη αθέτηση τώρα και στο μέλλον.
        </p>
        <p>
          Όλα τα δικαιώματα και τα ένδικα βοηθήματα σύμφωνα με τους όρους χρήσης
          είναι σωρευτικά και δεν αποκλείουν άλλα δικαιώματα και ένδικα
          βοηθήματα που προβλέπονται από το νόμο ή από άλλη συμφωνία.
        </p>
        <p>
          Εάν οποιοδήποτε τμήμα των όρων χρήσης θεωρηθεί ότι δεν ισχύει ή δεν
          μπορεί να εφαρμοστεί σύμφωνα με το εφαρμοστέο δίκαιο
          συμπεριλαμβανομένων, ενδεικτικά, των παραιτήσεων από εγγυήσεις και των
          περιορισμών ευθύνης, τότε ο άκυρος και ανεφάρμοστος όρος θα
          αντικατασταθεί από έναν έγκυρο και εφαρμόσιμο όρο που θα μοιάζει σε
          περιεχόμενο με τον αρχικό, ενώ η ισχύς των υπόλοιπων όρων θα
          παραμείνει ως έχει.
        </p>
        <p>
          25. Συμφωνείτε ότι η τυπωμένη έκδοση της Τοποθεσίας και των όρων
          χρήσης και οποιασδήποτε γνωστοποίησης που πραγματοποιήθηκε σε
          ηλεκτρονική μορφή θα συνιστά έγγραφη απόδειξη σε δικαστικές ή
          διοικητικές διαδικασίες.
        </p>
        <p>
          26. Πνευματικά δικαιώματα © 2024
          <span className="font-bold">Chapter 5 AE</span> Με επιφύλαξη παντός
          δικαιώματος.
        </p>
        <p className="font-bold"> Επικοινωνία:</p>
        <p>
          Για κάθε διευκρίνιση σχετική με την Εφαρμογή, οι χρήστες μπορούν να
          επικοινωνούν στο Τ: 2109525255 Δευτέρα έως Παρασκευή μεταξύ 11.00 &
          15.00
        </p>
      </div>
    </div>
  );
};

export default UserTerms;
