import axios from "axios";

const BASE_URL = "https://shark-app-2unnm.ondigitalocean.app";

export const validateQR = (acceptedTerms, uuid, navigate) => {
  axios
    .get(`${BASE_URL}/qrs/${uuid}/`)
    .then((res) => {
      if (!res.data.is_active) {
        throw new Error("InactiveQR");
      } else if (!acceptedTerms) {
        navigate(`/qr/${uuid}`, { replace: true });
      }
    })
    .catch((e) => {
      if (e.message === "InactiveQR") {
        navigate("activate", { replace: true });
      } else if (e.response && e.response.status === 404) {
        navigate("/", { replace: true });
      }
    });
};

export const validateClientId = (clientId, setIsValid, setErr) => {
  axios
    .post(`${BASE_URL}/mini-markets/validate-client/`, { client_id: clientId })
    .then((res) => {
      setIsValid(true);
    })
    .catch((err) => {
      if (err.response && err.response.status === 400) {
        setErr("Εσφαλμενος κωδικος πελατη");
      } else {
        setErr(err.message);
      }
    });
};
