import { createColumnHelper } from "@tanstack/react-table";
import Table from "./table";
import { formatDateToDay } from "../utils";

const ReceiptsPerDayTable = ({ receiptsPerDayData }) => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("day", {
      header: "ημερομηνια",
      cell: (info) => formatDateToDay(info.getValue()),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("count", {
      header: "συνολο",
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
  ];

  return (
    <Table
      data={receiptsPerDayData}
      columns={columns}
      emptyMsg="δεν υπαρχουν αποδειξεις"
    />
  );
};

export default ReceiptsPerDayTable;
