import { useEffect, useState } from "react";
import { getGeneralAdminData } from "../requests/admin/getData";

const AdminGeneralDashboard = ({ navigate }) => {
  const [generalAdminData, setGeneralAdminData] = useState();

  useEffect(() => {
    getGeneralAdminData(setGeneralAdminData, navigate);
  }, [navigate]);

  return (
    <div className="w-full flex items-start justify-between">
      <div className="flex flex-col justify-center items-center w-full space-y-4">
        <p>ενεργοποιησεις</p>
        {generalAdminData && (
          <p>
            {generalAdminData.activated_mini_markets}/
            {generalAdminData.total_mini_markets}
          </p>
        )}
      </div>
      <div className="flex flex-col justify-center items-center w-full space-y-4">
        <p>αποδειξεις</p>
        {generalAdminData && <p>{generalAdminData.receipts_total}</p>}
      </div>
      <div className="flex flex-col justify-center items-center w-full space-y-4">
        <p>νικητες</p>
        {generalAdminData && <p>{generalAdminData.winners_total}</p>}
      </div>
    </div>
  );
};

export default AdminGeneralDashboard;
