import axios from "axios";

// const BASE_URL = "http://127.0.0.1:8000";
const BASE_URL = "https://shark-app-2unnm.ondigitalocean.app";

export const adminLogin = (email, password, navigate, setErr) => {
  axios
    .post(`${BASE_URL}/users/login/`, {
      email,
      password,
    })
    .then((res) => {
      sessionStorage.setItem("adminToken", res.data.access_token);
      navigate("/ch5");
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        setErr("δεν υπαρχει λογαριασμος με αυτα τα στοιχεια");
      }
    });
};
