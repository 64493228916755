import { Outlet } from "react-router-dom";
import Footer from "../components/footer";
import headerImg from "../assets/Header_720x400px.png";

const PageLayout = ({ isMiniMarket }) => {
  var userAgent = window.navigator.userAgent;

  return (
    <div
      className={
        "w-screen relative flex flex-col justify-between overflow-y-auto font-tccc uppercase bg-coca-cola-light-gray items-center " +
        (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)
          ? "h-dvh"
          : "h-screen")
      }
    >
      <div className="flex flex-col flex-grow pb-4 md:max-w-screen-md">
        <img
          src={headerImg}
          className="w-full bg-contain"
          alt="Header Background"
        ></img>
        <Outlet />
      </div>
      <div className="w-full">
        <Footer isMiniMarket={isMiniMarket} />
      </div>
    </div>
  );
};

export default PageLayout;
