import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "../assets/icons";
import Input from "./input";

export const PasswordInput = ({ id, password, setPassword, setErr }) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <>
      <div className="relative w-full">
        <div
          className="absolute inset-y-0 end-2 flex items-center ps-3"
          onClick={() => {
            setShowPass(!showPass);
          }}
        >
          {showPass ? <EyeIcon /> : <EyeSlashIcon />}
        </div>
        <Input
          type={showPass ? "text" : "password"}
          id={id}
          value={password}
          onChange={setPassword}
          required={true}
          setErr={setErr}
        />
      </div>
    </>
  );
};
