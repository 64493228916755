import axios from "axios";
import { dataURLtoFile } from "../../utils";

const BASE_URL = "https://shark-app-2unnm.ondigitalocean.app";

export const miniMarketLogin = (clientId, password, setErr, navigate) => {
  axios
    .post(`${BASE_URL}/mini-markets/login/`, {
      email: clientId,
      password,
    })
    .then((res) => {
      sessionStorage.setItem("authToken", res.data.access_token);
      navigate("/mini-market");
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        setErr("δεν υπαρχει λογαριασμος με αυτα τα στοιχεια");
      }
    });
};

export const sendReicipt = (
  uuid,
  navigate,
  setWinner,
  setWinnerFormData,
  imgSrc
) => {
  axios
    .post(`${BASE_URL}/receipts/`, { mini_market: uuid })
    .then((res) => {
      var formData = new FormData();
      var file = dataURLtoFile(imgSrc, `${res.data.created_on}.webp`);
      formData.append("file", file);
      formData.append("receipt", res.data.id);
      setWinnerFormData(formData);
      setWinner(res.data.winner);
      navigate(`/qr/${uuid}/final`, { replace: true });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sendWinner = (
  fullname,
  phone,
  winnerFormData,
  setWinnerFormData,
  setSubmitted
) => {
  const formData = winnerFormData;
  formData.append("full_name", fullname);
  formData.append("phone", phone);
  axios
    .post(`${BASE_URL}/winners/`, winnerFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      setWinnerFormData(null);
      setSubmitted(true);
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const activateQR = (uuid, clientId, password, setErr, navigate) => {
  axios
    .patch(`${BASE_URL}/mini-markets/${uuid}/`, {
      client_id: clientId,
      uuid,
      password,
    })
    .then(() => {
      navigate("/login");
    })
    .catch((err) => {
      console.log(err);
      setErr("Κατι πηγε λαθος");
    });
};
