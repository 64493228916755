import { useNavigate } from "react-router-dom";
import { handleReturnToBack } from "../utils";
import TermsAndConditions from "../pages/TermsAndConditions";
import Contact from "../components/contact";
import ParticipationTerms from "../components/participationTerms";

const FooterLinksLayout = ({ component, isMiniMarket }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full h-full justify-between items-center">
      {component === "terms" && (
        <TermsAndConditions isMiniMarket={isMiniMarket} />
      )}
      {component === "contact" && <Contact />}
      {component === "participation" && <ParticipationTerms />}
      <button
        onClick={() => handleReturnToBack(navigate)}
        className="rounded-full bg-coca-cola-red border border-white w-min px-10 py-3 text-white font-bold text-xl uppercase"
      >
        επιστροφη
      </button>
    </div>
  );
};

export default FooterLinksLayout;
