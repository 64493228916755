import { useState } from "react";
import { useNavigate } from "react-router-dom";
import pinImg from "../assets/pinImg.png";
import { sendWinner } from "../requests/miniMarkets/sendData";
import Footer from "../components/footer";

const Final = ({
  winner,
  setAcceptedTerms,
  winnerFormData,
  setWinnerFormData,
}) => {
  var userAgent = window.navigator.userAgent;
  const navigate = useNavigate();
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendWinner(
      fullname,
      phone,
      winnerFormData,
      setWinnerFormData,
      setSubmitted,
    );
  };

  const handleReturnToHome = () => {
    setAcceptedTerms(false);
    navigate("/", { replace: true });
  };

  return (
    <div
      className={
        "w-screen relative flex flex-col justify-between overflow-y-auto font-tccc uppercase bg-coca-cola-light-gray items-center " +
        (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)
          ? "h-dvh"
          : "h-screen")
      }
    >
      <div className="h-full w-screen flex flex-col space-y-2 bg-coca-cola-red text-center items-center justify-center text-white">
        {winner ? (
          <div className="h-min flex flex-col items-center justify-between space-y-8 -mb-8">
            <div className="mt-8">
              <h1 className="text-4xl font-black">συγχαρητηρια</h1>
              <p className="text-xl font-black">κερδισες μια</p>
              <p className="text-xl font-black">δωροεπιταγη public</p>
              <p className="text-xl font-black">αξιας 20€</p>
            </div>
            {submitted ? (
              <div className="flex flex-col items-center">
                <p className="text-xl font-black">
                  η συμμετοχη σου καταχωρηθηκε
                </p>
                <p className="text-xl font-black">με επιτυχια</p>
              </div>
            ) : (
              <form
                className="flex flex-col space-y-6 w-full -mb-5 items-center justify-between"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col items-center space-y-4">
                  <div>
                    <p className="normal-case text-lg font-bold">
                      Στοιχεία Επικοινωνίας για
                    </p>
                    <p className="normal-case text-lg font-bold">
                      Παραλαβή Δώρου
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="name"
                      className="text-black text-sm text-center"
                    >
                      ονοματεπωνυμο
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="text-black text-lg text-center bg-white rounded-full px-4 py-1 disabled:bg-coca-cola-disabled"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                      disabled={submitted}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="phone"
                      className="text-black text-sm text-center"
                    >
                      αριθμος τηλεφωνου
                    </label>
                    <input
                      id="phone"
                      type="number"
                      minLength={10}
                      maxLength={10}
                      className="text-black text-lg text-center bg-white rounded-full px-4 py-1 disabled:bg-coca-cola-disabled"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      disabled={submitted}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="rounded-full bg-coca-cola-red border-2 border-white px-12 py-3 text-white font-black text-2xl uppercase disabled:bg-coca-cola-disabled"
                  disabled={submitted}
                >
                  υποβολη
                </button>
              </form>
            )}
          </div>
        ) : (
          <div className="h-max flex flex-col items-center justify-end space-y-8">
            <div>
              <h1 className="text-4xl font-black">ευχαριστουμε</h1>
              <p className="text-xl font-black">για τη συμμετοχη σου,</p>
              <p className="text-xl font-black">προσπαθησε ξανα σε</p>
              <p className="text-xl font-black">επομενη αγορα</p>
            </div>
            <button
              className="rounded-full bg-coca-cola-red border-2 border-white px-4 py-3 text-white font-black text-2xl uppercase disabled:bg-coca-cola-disabled z-20"
              onClick={handleReturnToHome}
            >
              αρχικη σελιδα
            </button>
          </div>
        )}
      </div>
      {winner ? (
        <div className="flex flex-col h-[35%] items-center justify-center space-y-2 mt-12 pb-2">
          <div className="h-12">
            <img src={pinImg} className="h-full bg-contain" alt="Pin"></img>
          </div>
          <div>
            <p className="font-black text-xl text-center">συντομα θα</p>
            <p className="font-black text-xl text-center">
              επικοινωνησουμε μαζι σου!
            </p>
          </div>
          <div>
            <p className="font-black text-center">
              κρατησε την αποδειξη που δηλωσες
            </p>
            <p className="font-black text-center">
              για να παραλαβεις το δωρο σου.
            </p>
          </div>
        </div>
      ) : (
        <div className="h-[35%] bg-coca-cola-light-gray w-full" />
      )}
      <Footer />
    </div>
  );
};

export default Final;
