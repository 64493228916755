import axios from "axios";

const BASE_URL = "https://shark-app-2unnm.ondigitalocean.app";

export const getMiniMarketDetails = (setDetails, setMiniMarketId, navigate) => {
  axios
    .get(`${BASE_URL}/mini-markets/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    })
    .then((res) => {
      setDetails(res.data[0]);
      setMiniMarketId(res.data[0].id);
    })
    .catch((e) => {
      console.log(e.message);
      if (e.response && e.response.status === 401) {
        sessionStorage.removeItem("authToken");
        navigate("/");
      }
    });
};

export const getMiniMarketReceipts = (
  setReceipts,
  duration,
  miniMarketId,
  navigate
) => {
  axios
    .get(
      `${BASE_URL}/receipts/?mini-market=${miniMarketId}&duration=${duration}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
      }
    )
    .then((res) => {
      setReceipts(res.data);
    })
    .catch((e) => {
      console.log(e.message);
      if (e.response && e.response.status === 401) {
        sessionStorage.removeItem("authToken");
        navigate("/");
      }
    });
};
