import { useEffect, useState } from "react";
import { getActivations, getActiveReport } from "../requests/admin/getData";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "./table";

const AdminMiniMarketsList = ({ navigate }) => {
  const [activations, setActivations] = useState();
  const columnHelper = createColumnHelper();

  const handleGenerateReport = () => {
    getActiveReport(navigate);
  };

  useEffect(() => {
    getActivations(setActivations, navigate);
  }, [navigate]);

  const columns = [
    columnHelper.accessor("client_id", {
      header: "κωδικος πελατη",
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("name", {
      header: "επωνυμια",
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("street", {
      header: "διευθυνση",
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("city", {
      header: "περιοχη",
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
  ];

  return (
    <div className="w-full overflow-y-auto flex flex-col space-y-4 items-center">
      <button
        className="rounded-full bg-coca-cola-red border border-white px-4 py-2 text-white font-bold text-lg uppercase self-end"
        onClick={handleGenerateReport}
      >
        Active Report
      </button>
      <Table
        data={activations}
        columns={columns}
        emptyMsg="Δεν υπαρχουν ενεργοποιησεις"
      />
    </div>
  );
};

export default AdminMiniMarketsList;
