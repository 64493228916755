import { createColumnHelper } from "@tanstack/react-table";
import Table from "./table";
import { formatDateToDay, formatDateToTime } from "../utils";

const ReceiptsTotalTable = ({ receiptsTotalData }) => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("", {
      header: "#",
      cell: (info) => info.row.index + 1,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("date", {
      header: "ημερομηνια",
      cell: (info) => formatDateToDay(info.row.original.created_on),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("time", {
      header: "ωρα",
      cell: (info) => formatDateToTime(info.row.original.created_on),
      footer: (info) => info.column.id,
    }),
  ];

  return (
    <Table
      data={receiptsTotalData}
      columns={columns}
      emptyMsg="δεν υπαρχουν αποδειξεις"
    />
  );
};

export default ReceiptsTotalTable;
