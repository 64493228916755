const MiniMarketTerms = () => {
  return (
    <div className="flex flex-col space-y-2 items-center normal-case px-8 py-4">
      <h1 className="uppercase text-2xl font-black text-center">
        ΟΡΟΙ ΣΥΜΜΕΤΟΧΗΣ ΚΑΤΑΣΤΗΜΑΤΩΝ
      </h1>
      <h2 className="text-xl font-bold text-center">"Coca-Cola Daily Wins"</h2>
      <div className="w-full flex flex-col space-y-2 text-justify">
        <p>
          1. Η ανώνυμη εταιρεία με την επωνυμία «CHAPTERFIVE ΑΕ», με Α.Φ.Μ.
          099899571 (Δ.Ο.Υ ΦΑΕ ΑΘΗΝΩΝ), η οποία εδρεύει στην Καλλιθέα Αττικής
          (Λεωφόρος Συγγρού 128, (στο εξής{" "}
          <span className="font-bold">«Διοργανώτρια»</span>), με αφορμή την
          προωθητική ενέργεια Coca-Cola daily wins η οποία διοργανώνεται για
          λογαριασμό του πελάτη της{" "}
          <span className="font-bold">«Coca-Cola 3Ε Ελλάδος ΑΒΕΕ»</span> που
          εδρεύει στην Αθήνα (Φραγκοκλησσιας 9, Μαρούσι), διοργανώνει Διαγωνισμό
          που υλοποιείται σε καταστήματα-mini market ανά την Ελλάδα μέσω ειδικής
          ιστοσελίδας www.dailywins.gr, δίνοντας την ευκαιρία στα συμμετέχοντα
          καταστήματα-mini market να κερδίσουν δώρα εφεξής ο{" "}
          <span className="font-bold">«Διαγωνισμός»</span> με σκοπό την προώθηση
          του εμπορικού σήματος Coca-Cola και των ομώνυμων προϊόντων σύμφωνα με
          τα κατωτέρω οριζόμενα.
        </p>
        <p>
          2. Στο Διαγωνισμό μπορούν να λάβουν μέρος mini market της Ελλάδος όπως
          έχουν οριστεί από την{" "}
          <span className="font-bold">«Coca-Cola 3Ε Ελλάδος ΑΒΕΕ»</span>.
        </p>
        <p>
          3. Διάρκεια του Διαγωνισμού. Ως διάρκεια διεξαγωγής του Διαγωνισμού
          ορίζεται το χρονικό διάστημα από 08.04.2024, ώρα 23.00 έως και
          31.12.2024, ώρα 23.59 (εφεξής « Διάρκεια»).
        </p>
        <p>
          4. Ρητά δηλώνεται και διευκρινίζεται ότι από το Διαγωνισμό εξαιρούνται
          οι εργαζόμενοι της Διοργανώτριας καθώς και των συνδεδεμένων αυτής
          επιχειρήσεων, της «Coca-Cola 3Ε Ελλάδος ΑΒΕΕ» καθώς και οι σύζυγοι και
          συγγενείς των ανωτέρω μέχρι β’ βαθμού.
        </p>
        <p className="font-bold">5. Τρόπος Συμμετοχής στο Διαγωνισμό:</p>
        <p>
          Για να συμμετάσχει ένα κατάστημα-mini market στον «Διαγωνισμός» θα
          πρέπει: <span className="font-bold">(α)</span> να τοποθετήσει το
          παρασχεθέν προωθητικό υλικό (poster, Pop υλικά) που θα παραλάβει από
          τον πωλητή της «Coca-Cola 3Ε Ελλάδος ΑΒΕΕ» στο κατάστημα-mini market
          του σε εμφανή σημεία, ή/και <span className="font-bold">(β)</span> να
          έχει διαθέσιμους προς πώληση στο κατάστημα πολυσυσκευασίες προϊόντων
          Coca-Cola ή/και <span className="font-bold">(γ)</span> να διατηρήσει
          το προωθητικό υλικό και να δουλεύει τους κωδικούς προϊόντων Coca-Cola
          καθ’ όλη την διάρκεια του «Διαγωνισμού».
        </p>
        <p>
          Κάθε κατάστημα-mini market για να συμμετέχει στον «Διαγωνισμό» θα
          πρέπει να προχωρήσει σε ενεργοποίηση της συμμετοχής του μέσω του QR
          και της συναφούς εφαρμογής που διαθέτουν τα υλικά POP που θα έχουν
          παραδοθεί από τον πωλητή στο κάθε κατάστημα-mini market. Αναλυτικά ο
          καταστηματάρχης θα πρέπει να σκανάρει το QR που βρίσκεται πάνω στα Pop
          Υλικά που έχει λάβει, να εισέλθει στην σχετική εφαρμογή αποδεχόμενος
          τους όρους και τις πολιτικές που διέπουν την λειτουργία της, και να
          εισάγει τον μοναδικό κωδικό πελάτη. Ο κωδικός πελάτη θα δίνεται από
          τον συνεργάτη της 3E σε κάθε κατάστημα. Κάθε κωδικός είναι μοναδικός
          και αντιστοιχεί σε ένα μοναδικό QR. Για να ολοκληρωθεί η ενεργοποίηση
          θα πρέπει το κατάστημα-mini market να ορίσει μυστικό κωδικό
          «password». Για τη δημιουργία μυστικού κωδικού «password» δεν
          απαιτείται ελάχιστος αριθμός χαρακτήρων/ γραμμάτων.
        </p>
        <p>
          Στη σελίδα{" "}
          <a
            href="https://dailywins.gr/"
            target="_blank"
            rel="noreferrer"
            className="underline text-blue-700"
          >
            https://dailywins.gr/
          </a>{" "}
          o υπεύθυνος ενεργοποιημένου καταστήματος κάθε φορά που σκανάρει εκ
          νέου το QR code και επιλέγει από τη home page της σελίδας το «ΓΙΑ
          ΚΑΤΑΣΤΗΜΑΤΑ ΜΟΝΟ: ΚΑΝΤΕ ΕΙΣΟΔΟ ΕΔΩ» θα είναι σε θέση να παρακολουθεί
          συγκεντρωτικά τον αριθμό αποδείξεων της επιχείρησής του που έχει
          συγκεντρώσει, διά της καταχώρισης αυτών από συμμετέχοντες καταναλωτές,
          ως τη δεδομένη στιγμή.
        </p>
        <div className="w-full px-4">
          <p>
            • Σε περίπτωση κατά την οποία οποιεσδήποτε παράμετροι του
            διαγωνισμού χρειαστεί να αλλάξουν κατά την διάρκεια αυτού η αλλαγή
            θα εφαρμόζεται ταυτόχρονα σε όλα τα συμμετέχοντα καταστήματα-mini
            market.
          </p>
          <p>
            • Κάθε συμμετέχον κατάστημα-mini market θα ενημερωθεί για το τελικό
            αποτέλεσμα των συμμετοχών και αν έχει κερδίσει τη δωροεπιταγή που
            του αντιστοιχεί αρχές κάθε επόμενου μήνα από την «Coca-Cola 3Ε
            Ελλάδος ΑΒΕΕ ». Αναλυτικά ο νικητής Μαρτίου θα ενημερωθεί αρχές
            Απριλίου, ο νικητής Απριλίου αρχές Μαΐου κοκ.
          </p>
          <p>
            • Η «Coca-Cola 3Ε Ελλάδος ΑΒΕΕ » θα δίνει τα στοιχεία επικοινωνίας
            του νικητή στη «Διοργανώτρια». Η «Διοργανώτρια» θα έρθει σε
            συνεννόηση με το κάθε συμμετέχον κατάστημα-mini market σε σχέση με
            τον τρόπο διάθεσης ή/και παράδοσης ή/και υλοποίησης του δώρου.
          </p>
          <p>
            • Σε περίπτωση συγκέντρωσης ομοίου αριθμού αποδείξεων θα γίνει
            κλήρωση ανάμεσα σε καταστήματα-mini market ισοβάθμησαν.
          </p>
        </div>
        <p>
          Κάθε μήνα το κατάστημα με τις περισσότερες καταχωρημένες αποδείξεις
          κερδίζει μια δωροεπιταγή αξίας 100€ για αγορές από τα καταστήματα
          Public. Κάθε μετέχον κατάστημα-mini market θα έχει δικαίωμα να
          διεκδικήσει μία φορά μία (1) δωροεπιταγή καθόλη την διάρκεια της
          ενέργειας.
        </p>
        <p>Τα διαθέσιμα δώρα είναι τα εξής:</p>
        <div className="w-full px-4">
          <p>
            • Μία (1) Δωροεπιταγή αξίας 100€ για αγορές από τα καταστήματα
            Public. Συνολικά θα διατεθούν ως δώρο 9 δωροεπιταγές μία για κάθε
            μήνα ενεργοποίησης του Διαγωνισμού.
          </p>
        </div>
        <p>Σημειώσεις σχετικά με τα δώρα:</p>
        <div className="w-full px-4">
          <p>
            • Τα δώρα που θα δοθούν είναι συγκεκριμένα, δεν μεταβιβάζονται και
            δεν ανταλλάσσονται με χρήματα ή άλλη υπηρεσία ή προϊόντα, δεν
            εκχωρούνται σε τρίτα πρόσωπα φυσικά ή νομικά ούτε αντικαθίστανται ή
            εξαργυρώνονται. Τα δώρα παρέχονται αποκλειστικά και μόνο εξ αφορμής
            και με αιτία το Πρόγραμμα, μη εφαρμοζομένων των διατάξεων περί
            ευθύνης του πωλητή. Σε κάθε περίπτωση η ευθύνη της Διοργανώτριας
            περιορίζεται σε τυχόν πράξεις ή παραλείψεις τους από δόλο ή βαριά
            αμέλεια τελούμενες και σε καμία περίπτωση δεν θα εκτείνεται σε
            πράξεις ή παραλείψεις που οφείλονται σε ελαφρά αμέλεια, τυχερά
            γεγονότα ή γεγονότα ανωτέρας βίας.
          </p>
          <p>
            • Η Διοργανώτρια δεν καλύπτει οποιοδήποτε κόστος, το οποίο
            ενδεχομένως δημιουργηθεί στο πρόσωπο του νικητή εκ της αξιοποίησης
            των Δώρων πέραν των αναφερομένων στο παρόν.
          </p>
        </div>
        <p className="font-bold">
          6. Ενημέρωση για Κατοχύρωση και Παράδοση των Δώρων
        </p>
        <p>
          Τα συμμετέχοντα καταστήματα-mini market θα ενημερωθούν για τον τρόπο
          με τον οποίο μπορούν να παραλάβουν το δώρο τους με e-mail στον
          λογαριασμό ηλεκτρονικού ταχυδρομείου τους ή τηλεφωνικά. Σε περίπτωση
          που δεν καταστεί δυνατή η επικοινωνία εντός 2 ημερών από την
          ημερομηνία ολοκλήρωσης του διαγωνισμού, χάνεται οριστικά το δικαίωμά
          του επί του σχετικού Δώρου. Σε περίπτωση που ο νόμιμος εκπρόσωπος του
          καταστήματος-mini market δεν επιθυμεί να αποδεχθεί το Δώρο ή αρνηθεί
          να υπογράψει βεβαίωση αποδοχής αυτού ή αρνηθεί να επιδείξει δημόσιο
          έγγραφο από το οποίο να προκύπτει η ταυτότητα και η ιδιότητά του ως
          νομίμου εκπροσώπου, το δικαίωμά του επί του δώρου χάνεται οριστικά.
        </p>
        <p>
          Η Διοργανώτρια δε θα ευθύνεται στην περίπτωση που για οποιοδήποτε
          τεχνικό λόγο είναι προσωρινώς αδύνατη η λήψη και καταχώρηση
          συμμετοχής, στοιχείων και απαντήσεων στο portal www.dailywins.gr. Σε
          κάθε περίπτωση η ευθύνη της Διοργανώτριας θα περιορίζεται σε πράξεις ή
          παραλείψεις της από δόλο ή βαρεία αμέλεια και σε καμία περίπτωση δε θα
          εκτείνεται σε τυχηρά γεγονότα ή γεγονότα ανωτέρας βίας.
        </p>
        <p>
          Η Διοργανώτρια διατηρεί το δικαίωμα να τροποποιήσει ή/και ανακαλέσει
          το διαγωνισμό ή να μεταβάλει τις ημερομηνίες διεξαγωγής του ή/και
          οποιονδήποτε άλλον όρο του καθώς και να αλλάξει τα προσφερόμενα δώρα
          με άλλα ίσης αξίας, τον τρόπο παροχής τους και τον αριθμό τους. Η
          Διοργανώτρια μπορεί επίσης να διακόψει ή να ακυρώσει το διαγωνισμό
          χωρίς ειδοποίηση για λόγους δημοσίου συμφέροντος ή ανωτέρας βίας ή για
          οποιοδήποτε εύλογο κατά την κρίση της λόγο. Ομοίως, η Διοργανώτρια
          διατηρεί το δικαίωμα ανά πάσα στιγμή να αποκλείσει από το διαγωνισμό
          κάποιο συμμετέχον κατάστημα-mini market ή να τους απαγορεύσει την
          περαιτέρω συμμετοχή χωρίς ειδοποίηση εφ’ όσον κρίνει ότι η συμμετοχή
          της έρχεται σε αντίθεση με διατάξεις του νόμου ή γίνεται κακόπιστα ή
          καταχρηστικά ή αντίθετα προς την καλή πίστη ή τα χρηστά και
          συναλλακτικά ήθη, ή γίνεται κατά τρόπο που αντιβαίνει το γράμμα και το
          πνεύμα του διαγωνισμού, καταστρατηγεί ή επιχειρεί να καταστρατηγήσει
          καθ’ οιονδήποτε τους όρους του, προσδίδει αθέμιτο προβάδισμα ή/και
          πλεονέκτημα σε κάποια από τα συμμετέχοντα καταστήματα-minimarket. Στις
          περιπτώσεις αυτές η Διοργανώτρια ουδεμία ευθύνη φέρει έναντι των
          συμμετεχουσών ή/και οποιουδήποτε τρίτου. Σε περίπτωση δε ματαίωσης του
          διαγωνισμού τα συμμετέχοντα δεν αποκτούν οποιοδήποτε δικαίωμα,
          απαίτηση και αξίωση κατά της Διοργανώτριας ούτε νομιμοποιούνται ή
          δικαιούνται να ζητήσουν τη συνέχιση του διαγωνισμού ή οποιαδήποτε
          αποζημίωση μη διενέργειάς του.
        </p>
        <p>
          Η Διοργανώτρια δεν φέρει καμία ευθύνη ποινική ή αστική προς τα
          συμμετέχοντα καταστήματα, για οποιαδήποτε βλάβη ήθελε προκληθεί σε
          αυτές και την επιχειρηματική τους δραστηριότητα άμεσα ή έμμεσα με το
          διαγωνισμό.
        </p>
        <p>
          Η συμμετοχή στο διαγωνισμό ενδέχεται να απαιτήσει τη δημιουργία
          αρχείου δεδομένων προσωπικού χαρακτήρα που θα περιλαμβάνει τα
          προσωπικά στοιχεία και πληροφορίες που γνωστοποίησαν οι συμμετέχοντες
          κατά τη συμμετοχή τους στο διαγωνισμό στο πλαίσιο της υποβολής τα
          συμμετοχής τους και της απονομής των δώρων. Υπεύθυνος επεξεργασίας των
          δεδομένων είναι η Διοργανώτρια. Νομική βάση της επεξεργασίας των
          προσωπικών δεδομένων αποτελεί η εκτέλεση υποχρεώσεως εκ της συμβάσεως
          που συνάπτεται στο πλαίσιο της διεξαγωγής του διαγωνισμού. Κάθε
          συμμετέχων έχει το δικαίωμα ενημέρωσης, πρόσβασης καθώς και το
          δικαίωμα αντίρρησης για την επεξεργασία των δεδομένων του σύμφωνα με
          το Ν. 4626/2019 και τον Γενικό Κανονισμό Προστασίας Δεδομένων (EΕ)
          2016/679 περί προστασίας από επεξεργασία δεδομένων προσωπικού
          χαρακτήρα. Οι συμμετέχοντες, εφόσον αποχωρήσουν από το διαγωνισμό
          διατηρούν το δικαίωμα να ζητήσουν εγγράφως τη διαγραφή των προσωπικών
          τους δεδομένων από το ως άνω αρχείο, επικοινωνώντας με τον Διοργανωτή,
          τηλέφωνο: 2109525255. Σύμφωνα με το Ν. 4626/2019 και Γενικό Κανονισμό
          Προστασίας Δεδομένων (EΕ) 2016/679, ως ισχύει, οι συμμετέχοντες έχουν
          δικαίωμα πρόσβασης, ενημέρωσης και αντίρρησης σχετικά με τα απλά
          προσωπικά τους δεδομένα που τηρεί ο Διοργανωτής κατά τα ανωτέρω.
        </p>
        <p>
          Η Chapter Five ΑΕ θα διατηρήσει τα προσωπικά σας δεδομένα για έξι
          μήνες μετά από τη λήξη του διαγωνισμού για την περίπτωση έγερσης τυχόν
          σχετικών αξιώσεων.
        </p>
        <p>
          Η συμμετοχή στο διαγωνισμό συνεπάγεται την ανεπιφύλακτη αποδοχή όλων
          των ανωτέρω όρων και προϋποθέσεων και την συνακόλουθη παραίτηση των
          συμμετεχόντων από κάθε σχετική αξίωση, απαίτηση και δικαίωμα έναντι
          της Διοργανώτριας.
        </p>
        <p>
          Οι παρόντες όροι διέπονται και συμπληρώνονται από το ελληνικό δίκαιο.
          Αρμόδια για την επίλυση οιασδήποτε διαφοράς σχετική με το διαγωνισμό
          είναι τα Δικαστήρια των Αθηνών.
        </p>
      </div>
    </div>
  );
};

export default MiniMarketTerms;
