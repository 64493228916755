import { useNavigate } from "react-router-dom";
import { getReport } from "../requests/admin/getData";
import { adminLogout } from "../utils";
import AdminWinnersList from "../components/adminWinnersList";
import { useState } from "react";
import AdminGeneralDashboard from "../components/adminGeneralDashboard";
import AdminMiniMarketsList from "../components/adminMiniMarketsList";

const AdminHome = () => {
  const navigate = useNavigate();
  var userAgent = window.navigator.userAgent;
  const [selectedTable, setSelectedTable] = useState("general");

  const handleGenerateReport = () => {
    getReport(navigate);
  };

  return (
    <div
      className={
        "w-screen relative flex flex-col overflow-y-auto font-tccc uppercase bg-coca-cola-light-gray items-center space-y-8 pt-8 px-4 " +
        (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)
          ? "h-dvh"
          : "h-screen")
      }
    >
      <div className="w-full flex justify-end items-center relative z-0">
        <button
          className="rounded-full bg-coca-cola-red border border-white px-4 py-2 text-white font-bold text-lg uppercase"
          onClick={handleGenerateReport}
        >
          report
        </button>
      </div>
      <div className="w-full flex items-center justify-center border-b-2 border-b-black">
        <button
          className={
            "w-full uppercase " +
            (selectedTable === "general" ? "bg-coca-cola-red" : "bg-gray-300")
          }
          onClick={() => {
            setSelectedTable("general");
          }}
        >
          γενικα
        </button>
        <button
          className={
            "w-full uppercase " +
            (selectedTable === "winners" ? "bg-coca-cola-red" : "bg-gray-300")
          }
          onClick={() => {
            setSelectedTable("winners");
          }}
        >
          νικητες
        </button>
        <button
          className={
            "w-full uppercase " +
            (selectedTable === "activations"
              ? "bg-coca-cola-red"
              : "bg-gray-300")
          }
          onClick={() => {
            setSelectedTable("activations");
          }}
        >
          ενεργοποιησεις
        </button>
      </div>
      {selectedTable === "general" && (
        <AdminGeneralDashboard navigate={navigate} />
      )}
      {selectedTable === "winners" && <AdminWinnersList navigate={navigate} />}
      {selectedTable === "activations" && (
        <AdminMiniMarketsList navigate={navigate} />
      )}
      <button
        onClick={() => adminLogout(navigate)}
        className="rounded-full bg-coca-cola-red border border-white w-min px-10 py-3 text-white font-bold text-xl uppercase"
      >
        εξοδος
      </button>
    </div>
  );
};

export default AdminHome;
