const ParticipationTerms = () => {
  return (
    <div className="flex flex-col space-y-2 items-center normal-case px-8 py-4">
      <h1 className="uppercase text-2xl font-black text-center">
        ΟΡΟΙ ΣΥΜΜΕΤΟΧΗΣ ΣΤΟ ΔΙΑΓΩΝΙΣΜΟ
      </h1>
      <h2 className="text-xl font-bold text-center">"Coca-Cola Daily Wins"</h2>
      <div className="w-full flex flex-col space-y-2 text-justify">
        <p>
          1. Η ανώνυμη εταιρεία με την επωνυμία «CHAPTERFIVE ΑΕ», με Α.Φ.Μ.
          099899571 (Δ.Ο.Υ ΦΑΕ ΑΘΗΝΩΝ), η οποία εδρεύει στην Καλλιθέα Αττικής
          (Λεωφόρος Συγγρού 128, (στο εξής{" "}
          <span className="font-bold">«Διοργανώτρια»</span>), με αφορμή την
          προωθητική ενέργεια{" "}
          <span className="font-bold">Coca-Cola daily wins</span> η οποία
          διοργανώνεται για λογαριασμό του πελάτη της{" "}
          <span className="font-bold">«Coca-Cola 3Ε Ελλάδος ΑΒΕΕ»</span> που
          εδρεύει στην Αθήνα (Φραγκοκλησσιας 9, Μαρούσι), προκηρύσσει Διαγωνισμό
          που υλοποιείται σε καταστήματα ανά την Ελλάδα μέσω ειδικής ιστοσελίδας
          www.dailywins.gr, δίνοντας την ευκαιρία στο κοινό να κερδίσει δώρα
          εφεξής ο <span className="font-bold">«Διαγωνισμός»</span>) σύμφωνα με
          τα κατωτέρω οριζόμενα.{" "}
        </p>
        <p>
          2. Στο Διαγωνισμό μπορούν να λάβουν μέρος όλοι οι κάτοικοι της Ελλάδος
          που έχουν συμπληρώσει το 18ο έτος της ηλικίας τους.
        </p>
        <p>
          3. Ρητά δηλώνεται και διευκρινίζεται ότι από το Διαγωνισμό εξαιρούνται
          οι εργαζόμενοι της Διοργανώτριας καθώς και των συνδεδεμένων αυτής
          επιχειρήσεων, της «Coca-Cola 3Ε Ελλάδος ΑΒΕΕ» καθώς και οι σύζυγοι και
          συγγενείς των ανωτέρω μέχρι β’ βαθμού.
        </p>
        <p>
          4. Διάρκεια του Διαγωνισμού. Ως διάρκεια διεξαγωγής του Διαγωνισμού
          ορίζεται το χρονικό διάστημα από 08.04.2024, ώρα 23.00 έως και
          31.12.2024, ώρα 23.59 (εφεξής « Διάρκεια»).
        </p>
        <p className="font-bold">5. Τρόπος Συμμετοχής στο Διαγωνισμό:</p>
        <p>
          Για να συμμετάσχει κάποιος στον Διαγωνισμό θα πρέπει:{" "}
          <span className="font-bold">(α)</span> Να επισκεφτεί ένα ή περισσότερα
          από τα συμμετέχοντα καταστήματα <span className="font-bold">(β)</span>{" "}
          να αγοράσει τουλάχιστον μια συσκευασία Coca-Cola{" "}
          <span className="font-bold">(γ)</span> να σκανάρει το QR που βρίσκεται
          στα προωθητικά υλικά του καταστήματος ώστε να εμφανιστεί η ιστοσελίδα
          του διαγωνισμού <span className="font-bold">(δ)</span> να καταχωρήσει
          την απόδειξη αγοράς <span className="font-bold">(ε)</span> να πάρει
          αυτόματα μέρος σε κλήρωση για δώρα.{" "}
        </p>
        <p>
          Κάθε ενδιαφερόμενος θα έχει την δυνατότητα να καταχωρήσει παραπάνω από
          μία αποδείξεις ώστε να είναι σε θέση να διεκδικήσει τα Δώρα με
          περισσότερες πιθανότητες.
        </p>
        <p>
          Διευκρινίζεται ότι για τη συμμετοχή στο Διαγωνισμό δεν απαιτείται η
          αγορά οποιουδήποτε προϊόντος. Με την επιφύλαξη των αμέσως ανωτέρω και
          κάθε ενδιαφερόμενος μπορεί να εξασφαλίσει τη συμμετοχή του στην
          κλήρωση και χωρίς την αγορά προϊόντος, αρκεί να δηλώσει συμμετοχή,
          αποστέλλοντας κατά τη Διάρκεια του Διαγωνισμού, ως ορίζεται υπό 4
          ανωτέρω, ηλεκτρονικό μήνυμα στη διεύθυνση ηλεκτρονικού ταχυδρομείου
          info@chapter5.gr με θέμα “Coca-Cola dailywins ”, αναγράφοντας τα
          στοιχεία του (ονοματεπώνυμο, αριθμό σταθερού ή/και κινητού τηλεφώνου,
          email ) προκειμένου να εγγραφεί και εν συνεχεία να λάβει μία
          συμμετοχή, με την οποία θα συμμετάσχει στον Διαγωνισμό.{" "}
        </p>
        <p>
          Τα καταστήματα, που συμμετέχουν στον Διαγωνισμό, έχουν εμφανή
          προωθητικά υλικά του διαγωνισμού.
        </p>
        <p>Δώρα – Κλήρωση</p>
        <p className="underline">Τα δώρα είναι:</p>
        <p className="underline">
          270 δωροεπιταγές αξίας 20 ευρώ από τα καταστήματα Public.
        </p>
        <p>
          Μέσα από τυχαία επιλογή από αλγόριθμο ένα τυχερός θα κερδίζει
          καθημερινά μια δωροεπιταγή αξίας 20 ευρώ από τα καταστήματα Public. Οι
          δωροεπιταγές είναι συνολικά 270 όσες και οι ημέρες ενεργοποίησης του
          διαγωνισμού. Οι κληρώσεις διεξάγονται ηλεκτρονικά με τρόπο αδιάβλητο
          που δεν επιτρέπει την οποιαδήποτε ανθρώπινη παρέμβαση. Οι νικητές θα
          πρέπει να καλέσουν στα τηλέφωνα της διοργανώτριας εταιρίας Chapter 5
          AE και να υποδείξουν την πρωτότυπη απόδειξη που καταχώρησαν στον
          διαγωνισμό ώστε να λάβουν την δωροεπιταγή Public. Κάθε νικητής με την
          παραλαβή της δωροεπιταγήσ θα λάβει από την διοργανώτρια εταιρία και
          αναλυτικές οδηγίες εξαργύρωσης.
        </p>
        <p>
          Ο κάθε συμμετέχων στον Διαγωνισμό θα μπορεί να διεκδικήσει
          δωροεπιταγές περισσότερο από μία φορά. Οι δωροεπιταγές δεν
          ανταλλάσσονται με χρήματα. Η Διοργανώτρια διατηρεί το δικαίωμα να
          παράσχει τις δωροεπιταγές με τρόπο διαφορετικό από τον αναφερόμενο στο
          παρόν άρθρο, σε περίπτωση που προκύψουν τεχνικά προβλήματα, τηρώντας
          όρους διαφάνειας και αντικειμενικότητας.
        </p>
        <p className="font-bold">
          6. Ενημέρωση για Κατοχύρωση και Παράδοση των Δώρων
        </p>
        <p>
          Ο νικητής θα ενημερωθεί για το τρόπο με τον οποίο μπορεί να παραλάβει
          το δώρο του με προσωπικό e-mail στον λογαριασμό ηλεκτρονικού
          ταχυδρομείου, τον οποίο έχει δηλώσει κατά την τηλεφωνική του
          επικοινωνία με την Διοργανώτρια. Σε περίπτωση που δεν καταστεί δυνατή
          η επικοινωνία με τον νικητή του Διαγωνισμού εντός 2 ημερών από την
          ημερομηνία αποστολής του e-mail ή σε περίπτωση που σε οποιοδήποτε
          χρονικό σημείο κριθεί ότι η συμμετοχή του δεν ήταν έγκυρη, το πρόσωπο
          αυτό χάνει οριστικά το δικαίωμά του επί του σχετικού Δώρου.
        </p>
        <p>
          Σε περίπτωση που ο νικητής του Διαγωνισμού δεν επιθυμεί να αποδεχθεί
          το Δώρο του ή αρνηθεί να υπογράψει βεβαίωση αποδοχής αυτού ή να
          επιδείξει δημόσιο έγγραφο από το οποίο να προκύπτει η ταυτότητά του,
          το πρόσωπο αυτό χάνει οριστικά το δικαίωμά του επί του Δώρου.
        </p>
        <p>
          7. Η εξαργύρωση Δώρου στους Νικητές τελεί υπό την αίρεση της επίδειξης
          της αστυνομικής τους ταυτότητας ή άλλου δημοσίου εγγράφου αποδεικτικού
          της ταυτότητάς τους σε εντεταλμένους προς τούτο υπαλλήλους της
          Διοργανώτριας ή/και σε οιοδήποτε άλλο πρόσωπο που θα τους παραδώσει το
          δώρο και συμπληρώνοντας και υπογράφοντας το ειδικό έντυπο παραλαβής
          δώρου. Επιπλέον για την παραλαβή του Δώρου, απαραίτητη είναι η
          επίδειξη της απόδειξης αγοράς, που χρησιμοποιήθηκε κατά την νικητήρια
          συμμετοχή.
        </p>
        <p>
          8. Η Διοργανώτρια δεν φέρει καμία ευθύνη εάν ο Νικητής έχει αρνηθεί ή
          αδρανήσει για οποιονδήποτε λόγο ή επιδείξει αδιαφορία ή αμέλεια
          προκειμένου να παραλάβει αυτοπροσώπως το Δώρο του. Επιπλέον, η ευθύνη
          για την πλήρη, ορθή συμπλήρωση αλλά και την ειλικρινή υποβολή των
          στοιχείων ανήκει στους συμμετέχοντες. Σε περίπτωση ελλιπούς,
          παραπλανητικής ή ανακριβούς συμπληρώσεως των στοιχείων η Διοργανώτρια
          διατηρεί το δικαίωμα μονομερούς ακύρωσης της συμμετοχής στο
          Διαγωνισμό. Στις περιπτώσεις αυτές ο Νικητής χάνει κάθε δικαίωμα,
          απαίτηση ή αξίωσή του επί του Δώρου, το οποίο παραμένει εις χείρας της
          Διοργανώτριας, η οποία απαλλάσσεται από κάθε υποχρέωση απόδοσής του
          και δικαιούται να προβεί κατά την απόλυτη κρίση και διακριτική της
          ευχέρεια είτε στην ακύρωση του μη αποδοθέντος Δώρου είτε στην
          περαιτέρω διάθεσή του.
        </p>
        <p>
          9. Το Δώρο που θα δοθεί στο πλαίσιο του Διαγωνισμού είναι απολύτως
          συγκεκριμένο και προσωποπαγές, ανεπίδεκτο μεταβιβάσεως και δεν
          ανταλλάσσεται με χρήματα ούτε είναι δυνατό να ζητηθεί η αντικατάστασή
          του με άλλα προϊόντα ή δώρα.
        </p>
        <p>
          10. Το Δώρο που θα παραδοθεί κατά τους παρόντες όρους παρέχεται με
          αιτία το Διαγωνισμό, αποκλειόμενης της εφαρμογής των διατάξεων περί
          ευθύνης του πωλητή ή άλλων διατάξεων που θεμελιώνουν ευθύνη αναφορικά
          με πραγματικά ελαττώματα, συμφωνημένες ιδιότητες, κ.λ.π., έναντι της
          Διοργανώτριας. Σε κάθε περίπτωση η ευθύνη της Διοργανώτριας
          περιορίζεται σε τυχόν πράξεις ή παραλείψεις τους από δόλο ή βαριά
          αμέλεια τελούμενες και σε καμία περίπτωση δεν θα εκτείνεται σε πράξεις
          ή παραλείψεις που οφείλονται σε ελαφρά αμέλεια, τυχερά γεγονότα ή
          γεγονότα ανωτέρας βίας.
        </p>
        <p>
          11. Διευκρινίζεται ότι η Διοργανώτρια δεν καλύπτει ουδέν κόστος, το
          οποίο ενδεχομένως δημιουργηθεί στο πρόσωπο του Νικητή εκ της
          αξιοποίησης των Δώρων πέραν των αναφερομένων στο παρόν.
        </p>
        <p>
          12. Η Διοργανώτρια δε θα ευθύνεται στην περίπτωση που για οποιοδήποτε
          τεχνικό λόγο είναι προσωρινώς αδύνατη η λήψη και καταχώρηση συμμετοχής
          στο σύστημά του ή/ και των τρίτων που ενεργούν κατ’ εντολή και για
          λογαριασμό του. Σε κάθε περίπτωση η ευθύνη της Διοργανώτριας θα
          περιορίζεται σε πράξεις ή παραλήψεις του από δόλο ή βαρεία αμέλεια και
          σε καμία περίπτωση δε θα εκτείνεται σε τυχηρά γεγονότα ή γεγονότα
          ανωτέρας βίας που συντρέξουν στο πρόσωπο του Διοργανωτή.
        </p>
        <p>
          13. Η Διοργανώτρια διατηρεί το δικαίωμα να τροποποιήσει ή/και
          ανακαλέσει το Διαγωνισμό ή να μεταβάλει τις ημερομηνίες διεξαγωγής του
          ή/και οποιονδήποτε άλλο όρο του, συμπεριλαμβανομένου χωρίς κανέναν
          περιορισμό του τρόπου διεξαγωγής του Διαγωνισμού καθώς και να αλλάξει
          το προσφερόμενο δώρο με άλλα ίσης αξίας κατά τη διάρκεια του
          Διαγωνισμού, τον τρόπο παροχής τους και τον αριθμό τους με ειδοποίηση
          πριν από την έναρξη ισχύος της μεταβολής. Οι μεταβολές αυτές θα
          γνωστοποιούνται στο κοινό κατά τρόπο παραπλήσιο με αυτόν της
          ανακοινώσεως της προκήρυξης προ μίας (1) εργάσιμης ημέρας από την
          έναρξη ισχύος της ως άνω τροποποίησης ή/και ανακλήσεως κ.λ.π.. Η
          Διοργανώτρια μπορεί επίσης να διακόψει ή να ακυρώσει τον Διαγωνισμό
          χωρίς ειδοποίηση για λόγους δημοσίου συμφέροντος ή ανωτέρας βίας ή για
          οποιοδήποτε λόγο. Στις περιπτώσεις αυτές η Διοργανώτρια ουδεμία ευθύνη
          φέρει έναντι των συμμετεχόντων ή/και οποιουδήποτε τρίτου. Σε περίπτωση
          δε ματαίωσης του Διαγωνισμού οι συμμετέχοντες δεν αποκτούν οποιοδήποτε
          δικαίωμα, απαίτηση και αξίωση κατά της Διοργανώτριας ούτε
          νομιμοποιούνται ή δικαιούνται να ζητήσουν τη συνέχιση του Διαγωνισμού
          ή οποιαδήποτε αποζημίωση λόγω οποιασδήποτε μεταβολής του.
        </p>
        <p>
          14. H Διοργανώτρια έχει το δικαίωμα να επαληθεύσει την εγκυρότητα των
          συμμετοχών και να αποκλείσει οποιονδήποτε συμμετέχοντα επιχειρεί να
          παρέμβει παρανόμως στη διαδικασία συμμετοχής ή του οποίου η συμμετοχή
          δεν είναι σύμφωνη με τους παρόντες όρους. Επιπλέον η Διοργανώτρια
          διατηρεί το δικαίωμα να ακυρώσει οποτεδήποτε τη συμμετοχή οποιουδήποτε
          συμμετέχοντα στον Διαγωνισμό ή να του απαγορεύσει την περαιτέρω
          συμμετοχή, κατά την απόλυτη διακριτική της ευχέρεια, καθ’ οιονδήποτε
          χρόνο, ακόμη και άνευ προειδοποιήσεως, εφ’ όσον κρίνει ότι η συμμετοχή
          του έρχεται σε αντίθεση με διατάξεις, του νόμου ή γίνεται κακόπιστα ή
          καταχρηστικά ή αντίθετα προς την καλή πίστη ή τα χρηστά και
          συναλλακτικά ήθη, την ελληνική νομοθεσία, γίνεται κατά τρόπο που
          αντιβαίνει το γράμμα και το πνεύμα του Διαγωνισμού, καταστρατηγεί ή
          επιχειρεί να καταστρατηγήσει καθ’ οιονδήποτε τους όρους του, προσδίδει
          αθέμιτο προβάδισμα ή/και πλεονέκτημα σε κάποιον από τους
          συμμετέχοντες.
        </p>
        <p>
          15. Η Διοργανώτρια δεν φέρει καμία ευθύνη ποινική ή αστική προς τους
          συμμετέχοντες, για οποιαδήποτε βλάβη ήθελε προκληθεί σε αυτούς άμεσα ή
          έμμεσα με το Διαγωνισμό.
        </p>
        <p>
          16. Ο Νικητής δίνει στην Διοργανώτρια το δικαίωμα δημοσίευσης του
          ονόματός του, για διαφημιστικούς σκοπούς, χωρίς άλλη αμοιβή εκτός από
          αυτή του δώρου του Διαγωνισμού.
        </p>
        <p>
          17. Η συμμετοχή στο Διαγωνισμό απαιτεί τη δημιουργία αρχείου δεδομένων
          προσωπικού χαρακτήρα που θα περιλαμβάνει τα προσωπικά στοιχεία και
          πληροφορίες που γνωστοποίησαν οι συμμετέχοντες κατά τη συμμετοχή τους
          στο Διαγωνισμό στο πλαίσιο της υποβολής τα συμμετοχής τους και της
          απονομής των δώρων. Υπεύθυνος επεξεργασίας των δεδομένων είναι η
          Διοργανώτρια. Νομική βάση της επεξεργασίας των προσωπικών δεδομένων
          αποτελεί η εκτέλεση υποχρεώσεως εκ της συμβάσεως που συνάπτεται στο
          πλαίσιο της διεξαγωγής του Διαγωνισμού. Κάθε συμμετέχων έχει το
          δικαίωμα ενημέρωσης, πρόσβασης καθώς και το δικαίωμα αντίρρησης για
          την επεξεργασία των δεδομένων του σύμφωνα με το Ν. 4626/2019 και τον
          Γενικό Κανονισμό Προστασίας Δεδομένων (EΕ) 2016/679 περί προστασίας
          από επεξεργασία δεδομένων προσωπικού χαρακτήρα. Οι συμμετέχοντες,
          εφόσον αποχωρήσουν από το Διαγωνισμό διατηρούν το δικαίωμα να ζητήσουν
          εγγράφως τη διαγραφή των προσωπικών τους δεδομένων από το ως άνω
          αρχείο, επικοινωνώντας με τον Διοργανωτή, τηλέφωνο: 2109525255.
          Σύμφωνα με το Ν. 4626/2019 και Γενικό Κανονισμό Προστασίας Δεδομένων
          (EΕ) 2016/679, ως ισχύει, οι συμμετέχοντες έχουν δικαίωμα πρόσβασης,
          ενημέρωσης και αντίρρησης σχετικά με τα απλά προσωπικά τους δεδομένα
          που τηρεί ο Διοργανωτής κατά τα ανωτέρω.
        </p>
        <p>
          Η Chapter Five ΑΕ θα κρατήσει τα προσωπικά σας δεδομένα για έξι μήνες
          μετά από τη λήξη του Διαγωνισμού για την περίπτωση έγερσης τυχόν
          σχετικών αξιώσεων.
        </p>
        <p>
          20. Η συμμετοχή στον Διαγωνισμό συνεπάγεται την ανεπιφύλακτη αποδοχή
          όλων των ανωτέρω όρων και προϋποθέσεων και την συνακόλουθη παραίτηση
          των Συμμετεχόντων από κάθε σχετική αξίωση, απαίτηση και δικαίωμα
          έναντι της Διοργανώτριας.
        </p>
        <p>
          21. Οι παρόντες όροι διέπονται και συμπληρώνονται από το ελληνικό
          δίκαιο. Αρμόδια για την επίλυση οιασδήποτε διαφοράς σχετική με τον
          Διαγωνισμό είναι τα Δικαστήρια των Αθηνών.
        </p>
      </div>
    </div>
  );
};

export default ParticipationTerms;
