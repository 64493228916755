import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = ({ isMiniMarket }) => {
  const [termsText, setTermsText] = useState("");
  useEffect(() => {
    if (isMiniMarket) {
      setTermsText("οροι & προϋποθεσεις καταστηματος");
    } else {
      setTermsText("οροι & προϋποθεσεις");
    }
  }, [isMiniMarket]);

  return (
    <div className="flex flex-col space-y-1 bg-white h-24 items-center py-6 border border-t-1 border-t-gray-300 w-screen">
      <div className="flex flex-row text-xs text-center items-center space-x-1 font-black">
        <p className={isMiniMarket ? "flex-1" : ""}>
          <Link to="terms-and-conditions"> {termsText} </Link>
        </p>
        <p>|</p>
        <p className="flex-1">
          <Link to="contact"> επικοινωνια</Link>
        </p>
      </div>
      <p className="text-center px-2 normal-case">Created by Chapter5</p>
    </div>
  );
};

export default Footer;
