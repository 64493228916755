import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { validateQR } from "../requests/miniMarkets/validation";

const QRGuard = ({ acceptedTerms }) => {
  const navigate = useNavigate();
  let { uuid } = useParams();

  useEffect(() => {
    validateQR(acceptedTerms, uuid, navigate);
  }, [acceptedTerms, navigate, uuid]);

  return <Outlet />;
};

export default QRGuard;
