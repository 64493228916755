import { useNavigate, useParams } from "react-router-dom";

const RequestAccess = () => {
  const navigate = useNavigate();
  let { uuid } = useParams();

  const handleGiveAccess = () => {
    navigate(`/qr/${uuid}/camera`, { replace: true });
  };

  const handleReturnToScanned = () => {
    navigate(`/qr/${uuid}`, { replace: true });
  };

  return (
    <div className="flex flex-col items-center space-y-4 px-4 text-center">
      <div className="font-black text-coca-cola-red pt-2">
        <h1 className="text-2xl">καθε μερα ενας νικητης!</h1>
      </div>
      <div className="rounded-md bg-coca-cola-red w-max text-white text-md">
        <p className="font-black px-3">αποκλειστικα στα mini markets</p>
      </div>
      <p className="font-black text-3xl">ενεργοποιηση καμερας</p>
      <p className="mb-2 normal-case">
        Παρακαλούμε δώστε πρόσβαση στην κάμερα του κινητού σας, η οποία
        απαιτείται για να βγάλετε την φωτογραφία και να την ανεβάσετε στο
        σύστημα.
      </p>
      <button
        onClick={handleGiveAccess}
        className="rounded-full bg-coca-cola-red border border-white px-6 py-3 text-white font-black text-2xl uppercase"
      >
        δωσε προσβαση
      </button>
      <button onClick={handleReturnToScanned} className="uppercase font-bold">
        ακυρωση
      </button>
    </div>
  );
};

export default RequestAccess;
