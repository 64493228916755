export const ErrAlert = ({ err, setErr }) => {
  return (
    <div
      id="alert"
      className="flex items-center p-4 mb-4 text-white rounded-full bg-red-400"
      role="alert"
    >
      <div className="text-xs">{err}</div>
      <button
        type="button"
        className="ms-auto text-white rounded-full inline-flex items-center justify-center h-8 w-8"
        onClick={() => setErr("")}
      >
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  );
};

export const SuccessAlert = ({ success, setSuccess }) => {
  return (
    <div
      id="alert"
      className="flex items-center p-4 mb-4 text-white rounded-full bg-green-400"
      role="alert"
    >
      <div className="text-xs">{success}</div>
      <button
        type="button"
        className="ms-auto text-white rounded-full inline-flex items-center justify-center h-8 w-8"
        onClick={() => setSuccess("")}
      >
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  );
};
