import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Scanned = ({ acceptedTerms, setAcceptedTerms, setIsMiniMarket }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setIsMiniMarket(false);
  }, [setIsMiniMarket]);

  const handleClick = () => {
    navigate("access", { replace: true });
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="font-black text-coca-cola-red pt-2">
        <h1 className="text-2xl text-center">καθε μερα ενας νικητης!</h1>
      </div>
      <div className="rounded-md bg-coca-cola-red w-max text-white text-md">
        <p className="font-black px-3">αποκλειστικα στα mini markets</p>
      </div>
      <div className="flex flex-col items-center justify-center w-full p-3 space-y-3">
        <p
          className={
            "font-black text-center text-3xl w-full " +
            (acceptedTerms ? "text-black" : "text-coca-cola-disabled")
          }
        >
          ανεβασε φωτογραφια της αποδειξης σου
        </p>
        <button
          className="rounded-full bg-coca-cola-red border border-white px-10 py-3 text-white font-black text-2xl uppercase disabled:bg-coca-cola-disabled"
          disabled={!acceptedTerms}
          onClick={handleClick}
        >
          <p className="text-center text-2xl text-white font-bold">εδω</p>
        </button>

        <div className="flex items-center justify-center space-x-2">
          <input
            id="terms"
            className="accent-coca-cola-red"
            checked={acceptedTerms}
            onChange={() => setAcceptedTerms(!acceptedTerms)}
            type="checkbox"
          />
          <label htmlFor="terms">
            αποδοχη των{" "}
            <Link
              className="underline text-coca-cola-red"
              to="participation-terms"
            >
              ορων
            </Link>
          </label>
        </div>
        <div className="w-full">
          <p className="text-md text-center py-8 px-4">
            για καταστηματα μονο: καντε εισοδο{" "}
            <Link
              to="/login"
              className="underline font-bold text-coca-cola-red"
            >
              εδω
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Scanned;
