import axios from "axios";

const FileDownload = require("js-file-download");
// const BASE_URL = "http://127.0.0.1:8000";
const BASE_URL = "https://shark-app-2unnm.ondigitalocean.app";

export const getWinners = (setWinners, navigate) => {
  axios
    .get(`${BASE_URL}/winners/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
      },
    })
    .then((res) => {
      setWinners(res.data);
    })
    .catch((e) => {
      console.log(e.message);
      if (e.response && e.response.status === 401) {
        sessionStorage.removeItem("adminToken");
        navigate("/ch5/login");
      }
    });
};

export const getReport = (navigate) => {
  axios
    .get(`${BASE_URL}/mini-markets/receipts-report/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
      },
      responseType: "blob",
    })
    .then((res) => {
      const headers = res.headers["content-disposition"];
      const filename = headers.split('"')[1];
      FileDownload(res.data, filename);
    })
    .catch((e) => {
      console.log(e.message);
      if (e.response && e.response.status === 401) {
        sessionStorage.removeItem("adminToken");
        navigate("/ch5/login");
      }
    });
};

export const getActiveReport = (navigate) => {
  axios
    .get(`${BASE_URL}/mini-markets/active-report/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
      },
      responseType: "blob",
    })
    .then((res) => {
      const headers = res.headers["content-disposition"];
      const filename = headers.split('"')[1];
      FileDownload(res.data, filename);
    })
    .catch((e) => {
      console.log(e.message);
      if (e.response && e.response.status === 401) {
        sessionStorage.removeItem("adminToken");
        navigate("/ch5/login");
      }
    });
};

export const getGeneralAdminData = (setGeneralAdminData, navigate) => {
  axios
    .get(`${BASE_URL}/mini-markets/general-admin-data/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
      },
    })
    .then((res) => {
      setGeneralAdminData(res.data);
    })
    .catch((e) => {
      console.log(e.message);
      if (e.response && e.response.status === 401) {
        sessionStorage.removeItem("adminToken");
        navigate("/ch5/login");
      }
    });
};

export const getActivations = (setActivations, navigate) => {
  axios
    .get(`${BASE_URL}/mini-markets/?active=True`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
      },
    })
    .then((res) => {
      setActivations(res.data);
    })
    .catch((e) => {
      console.log(e.message);
      if (e.response && e.response.status === 401) {
        sessionStorage.removeItem("adminToken");
        navigate("/ch5/login");
      }
    });
};
